// Import CSS
// import '../css/gijgo.min.css';
// import '../css/theme.default.min.css';
// import '../css/theme.dark.min.css';
// import '../css/theme.bootstrap_4.min.css';
// import './addons/pager/jquery.tablesorter.pager.css';
import 'simplelightbox/dist/simple-lightbox.css';
// import '../css/lightbox.rotate.img.css';
// import 'datatables.net/css/datatables.min.css';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-fixedheader-bs5/css/fixedHeader.bootstrap5.min.css';
// import 'dropzone/dist/dropzone.css';
// import 'dark-mode-switch/dark-mode.css'; // It's in myStyles.css
import '../css/styles.css';
import '../css/myStyles.css';
// Import JS
import * as bootstrap from 'bootstrap';
// import './bootstrap.bundle.min.js';
import './fonts.js';
// import * as feather from 'feather-icons'; // if not imported with webpack.ProvidePlugin
// import './font-awesome-5.11.2-all.min.js';
import Chart from 'chart.js/auto';
import './jquery.html5storage.min.js';
// import './datatables.min.js';
// import '../demo/chart-area-demo.js';
// import '../demo/chart-bar-demo.js';
// import '../demo/chart-pie-demo.js';
// import '../demo/datatables-demo.js';
import './theme.js';
import datatablesFrench from './datatables_fr_FR.json';
import jszip from 'jszip';
window.JSZip = jszip;
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import 'datatables.net';
import 'datatables.net-bs5';
// import 'datatables.net-autofill-bs5';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-colreorder-bs5';
// import 'datatables.net-fixedcolumns-bs5';
import 'datatables.net-fixedheader-bs5';
// import 'datatables.net-keytable-bs5';
// import 'datatables.net-responsive-bs5';
// import 'datatables.net-rowgroup-bs5';
// import 'datatables.net-rowreorder-bs5';
// import 'datatables.net-scroller-bs5';
import 'datatables.net-searchbuilder-bs5';
// import 'datatables.net-searchpanes-bs5';
// import './jquery.easing.min.js';
// import './gijgo.fr.min.js';
// import './jquery.tablesorter.min.js';
// import './jquery.tablesorter.widgets.min.js';
// import './widget-cssStickyHeaders.min.js';
// import './addons/pager/jquery.tablesorter.pager.min.js';
// import './lightbox.rotate.img.js';
// import './smooth.scroll.anchor.js';
// import './FileSaver.min.js';
// import 'tableexport';
// import Dropzone from 'dropzone';
import SimpleLightbox from "simplelightbox";
// import simpleLightbox from "simpleLightbox/dist/simple-lightbox.jquery";
import regeneratorRuntime, { async } from "regenerator-runtime"; // Async/Await made available here
import 'litepicker';
import 'litepicker/dist/plugins/ranges';
// import 'litepicker/dist/plugins/mobilefriendly';
import 'dark-mode-switch';

// Set new default font family and font color to mimic Bootstrap's default styling
// Chart.defaults.global.defaultFontFamily = 'Metropolis, -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
// Chart.defaults.global.defaultFontColor = "#858796";

var globals,
App = {

	settings: {
		defLatLng: [48.86, 2.33],
		defZoom: 6,
		bottomScrolled: false,
		getFilter: false,
		myEvent: 'loadEvent',
		myPage: '',
		isMobile: false,
		serverAddress: "https://bo.mozart-autos.com/db/",
		serverBaseUrl: "https://bo.mozart-autos.com/",
		today: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
		myLineChart: false,
		myBarChart: false,
		myPieChart: false,
		myGraphicalMySales :false,
		myGraphicalEvolutionSales: false,
		myGraphicalMyPrice: false,
		myGraphicalBasePrice: false,
		myGraphicalSalesBySeller: false,
		currentGraph: undefined,
		pass: $.localStorage.getItem('pass'),
		login: $.localStorage.getItem('login'),
		pwd: $.localStorage.getItem('pwd'),
		name: $.localStorage.getItem('name'),
		firstname: $.localStorage.getItem('firstname'),
		phone: $.localStorage.getItem('phone'),
		id: $.localStorage.getItem('id'),
		email: $.localStorage.getItem('email'),
		active: $.localStorage.getItem('active'),
		type: $.localStorage.getItem('type'),
		adminPass: '',
		mails: $.localStorage.getItem('mails'),
		phone2: $.localStorage.getItem('phone2'),
		address: $.localStorage.getItem('address'),
		address2: $.localStorage.getItem('address2'),
		zip: $.localStorage.getItem('zip'),
		city: $.localStorage.getItem('city'),
		country: $.localStorage.getItem('country'),
		wpid: $.localStorage.getItem('wpid'),
		birthdate: $.localStorage.getItem('birthdate'),
		job: $.localStorage.getItem('job'),
		avatar: $.localStorage.getItem('avatar'),
		initials: $.localStorage.getItem('initials'),
		comments: $.localStorage.getItem('comments'),
		site: $.sessionStorage.getItem('site'),
		changeSite: $.sessionStorage.getItem('changeSite'),
		acknowledgement: $.localStorage.getItem('acknowledgement'),
		dataTablesFrench: {},
		sales: {
			currentPage: 1,
			maxPage: 9,
			sumGroups: {},
			maths: {
				"+": function(a) {return Number(a)},
				"-": function(a) {return Number(-a)},
				"/": function(a, b) {return Number.isFinite(Number(a)/Number(b)) ? Number(a)/Number(b) : 0},
				"*": function(a, b) {return Number(a)*Number(b)},
				"=": function(a) {return 0}
			},
			lockedElements: []
		},
		canvases: [],
		charts: []
	},
	refreshGlobals: function(data) {
		globals.pass = data.pass;
		globals.login = data.login;
		globals.pwd = data.pwd;
		globals.name = data.name;
		globals.firstname = data.firstname;
		globals.phone = data.phone;
		globals.id = data.id;
		globals.email = data.email;
		globals.active = data.active;
		globals.type = data.type;
		globals.mails = data.mails;
		globals.phone2 = data.phone2;
		globals.address = data.address;
		globals.address2 = data.address2;
		globals.zip = data.zip;
		globals.city = data.city;
		globals.country = data.country;
		globals.wpid = data.wpid;
		globals.birthdate = data.birthdate;
		globals.job = data.job;
		/* globals.monthlyPrice = data.monthlyPrice */
		globals.avatar = data.avatar;
		globals.initials = data.initials;
		globals.comments = data.comments;
		globals.site = data.site;
		// console.log("refreshGlobals: "+globals.site);
	},
	init: function() {
		// kick things off
		globals = this.settings;
		// Dropzone.autoDiscover = false;
		this.bindUIActions();
		$("#now-date").append(globals.year);
		// console.log(datatablesFrench)
		fetch(datatablesFrench).then(data => data.json()).then(res => {
			globals.dataTablesFrench = res;
		})
		// App.setupSumGroups();
		setInterval(() => {
			globals.sales.lockedElements.shift()
		}, 100);
		App.getThe15FuckingGraphs()
	},
	fields: {
		required: async function(myFomId = null) {
      console.log('required');
			const bodyFormData = new FormData();
			bodyFormData.append('req', "salesController");
			bodyFormData.append('action', "getInputFieldsRequired");
			bodyFormData.append("pwd", globals.pwd);
			bodyFormData.append('site', sessionStorage.getItem('site'));
			const response = await fetch(globals.serverAddress, {
				body: bodyFormData,
				method: "POST"
			});
			const data = await response.json();
			if(data.status && data.status == 400) {
				return;
			}
			if(!myFomId) {
				return data;
			}
			const myForm = document.querySelector(myFomId);
      let suffixe = '';
      if(myFomId == '#saleVOForm') {
        suffixe = "VO"
      }
			const siteSelect = myForm.querySelector('#site'+ suffixe);
			const marqueSelect = myForm.querySelector('#brand'+ suffixe);
			const marqueRepSelect = myForm.querySelector('#brandRep'+ suffixe);
			const sellerSelect = myForm.querySelector('#seller'+ suffixe);
			const assistantSelect = myForm.querySelector('#assistant'+ suffixe);
			const corporationSelect = myForm.querySelector('#corporation'+ suffixe);
      const tcoSelect = myForm.querySelector('#tco'+ suffixe);

      // console.log()

			if(siteSelect) siteSelect.innerHTML = "";
			if(marqueSelect) marqueSelect.innerHTML = "";
			if(marqueRepSelect) marqueRepSelect.innerHTML = "";
			if(sellerSelect) sellerSelect.innerHTML = "";
			if(assistantSelect) assistantSelect.innerHTML = "";
			if(corporationSelect) corporationSelect.innerHTML = "";
      if(tcoSelect) tcoSelect.innerHTML = "";

			let option = document.createElement('option');
			option.value = 0;
			option.innerHTML = "Sélectionnez le site";
			siteSelect.prepend(option);
			data.sites.forEach(site => {
				const option = document.createElement('option');
				option.value = site.auto_s;
				option.innerHTML = site.name_s;
				siteSelect.appendChild(option);
			})
			siteSelect.value = sessionStorage.getItem('site');

			option = null;
			option = document.createElement('option');
			option.value = 0;
			option.innerHTML = "Sélectionnez la Marque";
			marqueSelect.prepend(option);
			marqueRepSelect.prepend(option.cloneNode(true));
			data.marques.forEach(marque => {
				const option = document.createElement('option');
				option.value = marque.Id;
				option.innerHTML = marque.Libelle;
				marqueSelect.appendChild(option);
				marqueRepSelect.appendChild(option.cloneNode(true));
			})
			marqueSelect.value = 0;
			marqueRepSelect.value = 0;

			option = null;
			option = document.createElement('option');
			option.value = 0;
			option.innerHTML = "Sélectionnez Un Vendeur(se)";
			sellerSelect.prepend(option);
      let presentId = []
			if(data.vendeurs && data.vendeurs.length > 0) {
				data.vendeurs.forEach(vendeur => {
					const option = document.createElement('option');
          presentId.push(vendeur.auto_u)
					option.value = vendeur.auto_u;
					option.innerHTML = vendeur.firstname_u;
					sellerSelect.appendChild(option);
				});
			}
      if(presentId.includes(globals.id)) {
        sellerSelect.value = globals.id;
      }
      else {
        sellerSelect.value = 0;
      }

			option = null;
			option = document.createElement('option');
			option.value = 0;
			option.innerHTML = "Sélectionnez un(e) Assistant(e)";
			assistantSelect.prepend(option);
			if(data.assistants && data.assistants.length > 0) {
				data.assistants.forEach(assistant => {
					const option = document.createElement('option');
					option.value = assistant.auto_u;
					option.innerHTML = assistant.firstname_u;
					assistantSelect.appendChild(option);
				})
			}
			assistantSelect.value = 0;
			
      option = null;
			option = document.createElement('option');
			option.value = 0;
			option.innerHTML = "Sélectionnez l'organisme de financement";
			corporationSelect.prepend(option);
			if(data.corporations && data.corporations.length > 0) {
				data.corporations.forEach(corporation => {
					const option = document.createElement('option');
					option.value = corporation.auto_sf;
					option.innerHTML = corporation.name_sf;
					corporationSelect.appendChild(option);
				})
			}
			corporationSelect.value = 0;

      if(myFomId == '#saleVOForm') {
        return;
      }
      option = null;
			option = document.createElement('option');
			option.value = 0;
			option.innerHTML = "Sélectionnez le code TCO";
			tcoSelect.prepend(option);
			if(data.tco && data.tco.length > 0) {
				data.tco.forEach(code => {
					const option = document.createElement('option');
					option.value = code.Id;
					option.innerHTML = code.code;
					tcoSelect.appendChild(option);
				})
			}
			tcoSelect.value = 0;
			// console.log(data);
		},
		sellers: async function(thisInput, fillValue = 0) {
			const site = thisInput.value;
			const myFomId = $(thisInput).closest('form').attr('id');
			const myForm = document.getElementById(myFomId);
      let suffixe = '';
      if(myFomId == 'saleVOForm') {
        suffixe = "VO"
      }
			const sellerSelect = myForm.querySelector('#seller'+ suffixe);
			const bodyFormData = new FormData()
			bodyFormData.append('req', "salesController");
			bodyFormData.append('action', "getInputFieldsSellers");
			bodyFormData.append('id', globals.id ?? sessionStorage.getItem("id"));
			bodyFormData.append("pwd", globals.pwd);
			bodyFormData.append('site', site ?? sessionStorage.getItem("site"));
			const response = await fetch(globals.serverAddress, {
				body: bodyFormData,
				method: "POST"
			});
			const data = await response.json();
			if(data.status && data.status == 400) {
				return;
			}
			sellerSelect.innerHTML = "";
			const option = document.createElement('option');
			option.value = 0;
			option.innerHTML = "Sélectionnez un vendeur";
			sellerSelect.prepend(option);
			data.vendeurs?.forEach(vendeur => {
				const option = document.createElement('option');
				option.value = vendeur.auto_u;
				option.innerHTML = vendeur.firstname_u;
				sellerSelect.appendChild(option);
			})
			sellerSelect.value = fillValue;
			// console.log(data);
		},
		assistants: async function(thisInput) {
			const site = thisInput.value;
			const myFomId = $(thisInput).closest('form').attr('id');
			const myForm = document.getElementById(myFomId);
      let suffixe = '';
      if(myFomId == 'saleVOForm') {
        suffixe = "VO"
      }
			const assistantSelect = myForm.querySelector('#assistant'+suffixe);
			const bodyFormData = new FormData()
			bodyFormData.append('req', "salesController");
			bodyFormData.append('action', "getInputFieldsAssistants");
			bodyFormData.append('id', globals.id);
			bodyFormData.append("pwd", globals.pwd);
			bodyFormData.append('site', site ?? sessionStorage.getItem("site"));
			const response = await fetch(globals.serverAddress, {
				body: bodyFormData,
				method: "POST"
			});
			const data = await response.json();
			if(data.status && data.status == 400) {
				return;
			}
			assistantSelect.innerHTML = "";
			const option = document.createElement('option');
			option.value = 0;
			option.innerHTML = "Sélectionnez un(e) Assistant(e)";
			assistantSelect.prepend(option);
			if(data.assistants && data.assistants.length > 0) {
				data.assistants.forEach(assistant => {
					const option = document.createElement('option');
					option.value = assistant.auto_u;
					option.innerHTML = assistant.firstname_u;
					assistantSelect.appendChild(option);
				})
			}
			assistantSelect.value = 0;
		},
		models: async function(thisInput = null, fillValueOrIdBrand = 0) {
			console.log(thisInput);
			if(typeof thisInput == 'string') {
				thisInput = document.querySelector(thisInput);
			}
			if(!thisInput) {
				return data;
			}
			const marque = thisInput.value;
      const myFomId = $(thisInput).closest('form').attr('id');
      // console.log(marque, myFomId);
      const myForm = document.getElementById(myFomId);
      
      let suffixe = '';
      if(myFomId == 'saleVOForm') {
        suffixe = "VO"
      }
      console.log(myFomId)
      console.log("querying", '#model'+suffixe)
      const modelSelect = myForm.querySelector('#model'+suffixe);
      console.log(modelSelect)
      // cas part pour mazda

      console.log('marque: ', Number(marque))
      if(Number(marque) == 3) {
        document.querySelector('#helpVDVKMod').setAttribute('readonly','true');
      }
      else {
        document.querySelector('#helpVDVKMod').removeAttribute('readonly');
      }
			// console.log(thisInput)
			const bodyFormData = new FormData()
			bodyFormData.append('req', "salesController");
			bodyFormData.append('action', "getInputFieldsModels");
			bodyFormData.append('id', globals.id ?? sessionStorage.getItem("id"));
			bodyFormData.append("pwd", globals.pwd);
			bodyFormData.append('marque', marque);
			const response = await fetch(globals.serverAddress, {
				body: bodyFormData,
				method: "POST"
			});
			const data = await response.json();
			// console.log(data);
			if(data.status && data.status == 400) {
				return;
			}


			// console.log(marque)


			modelSelect.innerHTML = "";
			const option = document.createElement('option');
			option.value = 0;
			option.innerHTML = "Sélectionnez un Modèle";
			modelSelect.prepend(option);
			data.models?.forEach(model => {
				const option = document.createElement('option');
				option.value = model.Id;
				option.innerHTML = model.Libelle;
				option.setAttribute('data-value', model.valeur_prct);
				option.setAttribute('data-prepa-stellantis', model.prepa_stellantis_vo);
				option.setAttribute('data-transport-stellantis', model.transport_stellantis_vo);
				modelSelect.appendChild(option);
			})
			modelSelect.value = fillValueOrIdBrand;
		},
		modelsRep: async function(thisInput = null, fillValueOrIdBrand = 0) {
			console.log(thisInput);
			if(typeof thisInput == 'string') {
				thisInput = document.querySelector(thisInput);
			}
			if(!thisInput) {
				return data;
			}
			const marque = thisInput.value;
			// console.log(thisInput)
			const bodyFormData = new FormData()
			bodyFormData.append('req', "salesController");
			bodyFormData.append('action', "getInputFieldsModels");
			bodyFormData.append('id', globals.id ?? sessionStorage.getItem("id"));
			bodyFormData.append("pwd", globals.pwd);
			bodyFormData.append('marque', marque);
			const response = await fetch(globals.serverAddress, {
				body: bodyFormData,
				method: "POST"
			});
			const data = await response.json();
			// console.log(data);
			if(data.status && data.status == 400) {
        console.log('failed to fetch')
				return;
			}


			// console.log(marque)
			const myFomId = $(thisInput).closest('form').attr('id');
			// console.log(marque, myFomId);
			const myForm = document.getElementById(myFomId);
      let suffixe = '';
      if(myFomId == 'saleVOForm') {
        suffixe = "VO"
      }
			const modelSelect = myForm.querySelector('#modelsRep' + suffixe);


			modelSelect.innerHTML = "";
			const option = document.createElement('option');
			option.value = 0;
			option.innerHTML = "Sélectionnez un Modèle";
			modelSelect.prepend(option);
			data.models?.forEach(model => {
				const option = document.createElement('option');
				option.value = model.Id;
				option.innerHTML = model.Libelle;
				option.setAttribute('data-value', model.valeur_prct);
				modelSelect.appendChild(option);
			})
			modelSelect.value = fillValueOrIdBrand;
		},
    stock: async function (thisInput = null, fillValue = 0) {
      if(typeof thisInput == 'string') {
				thisInput = document.querySelector(thisInput);
			}
			if(!thisInput) {
				return data;
			}
			const type_vo = thisInput.value;
			// console.log(thisInput)
			const bodyFormData = new FormData()
			bodyFormData.append('req', "salesController");
			bodyFormData.append('action', "getInputFieldsStock");
			bodyFormData.append('id', globals.id ?? sessionStorage.getItem("id"));
			bodyFormData.append("pwd", globals.pwd);
			bodyFormData.append('type_vo', type_vo);
			const response = await fetch(globals.serverAddress, {
				body: bodyFormData,
				method: "POST"
			});
			const data = await response.json();
			// console.log(data);
			if(data.status && data.status == 400) {
        console.log('failed to fetch')
				return;
			}


			// console.log(marque)
			const myFomId = $(thisInput).closest('form').attr('id');
			// console.log(marque, myFomId);
			const myForm = document.getElementById(myFomId);
      let suffixe = '';
      if(myFomId == 'saleVOForm') {
        suffixe = "VO"
      }
			const stockSelect = myForm.querySelector('#typeStock'+suffixe);

      if(myFomId == 'saleVOForm') {
        return;
      }
			stockSelect.innerHTML = "";
			const option = document.createElement('option');
			option.value = 0;
			option.innerHTML = "Sélectionnez l'origine du Véhicule";
			stockSelect.prepend(option);
			data.stock?.forEach(stock => {
				const option = document.createElement('option');
				option.value = stock.Id;
				option.innerHTML = stock.Libelle;
				option.setAttribute('data-value', stock.valeur);
				stockSelect.appendChild(option);
			})
			stockSelect.value = fillValue;
      if (Number(document.getElementById('brand').value) == 3) {
        if (thisInput.value == "VD") {
          // 3.5
          document.getElementById('helpVDVKMod').value = 3.5
        }
        else if(thisInput.value == "VK") {
          // 5.25
          document.getElementById('helpVDVKMod').value = 5.25;
        }
        else {
          document.getElementById('helpVDVKMod').value = 0;
        }
      }
      else {
        document.getElementById('helpVDVKMod').value = 0
      }

    },
    tauxFin: async function (thisInput = null, fillValue = 0) {
      if(typeof thisInput == 'string') {
				thisInput = document.querySelector(thisInput);
			}
			if(!thisInput) {
				return data;
			}
			const auto_sf = thisInput.value;
			// console.log(thisInput)
			const bodyFormData = new FormData()
			bodyFormData.append('req', "salesController");
			bodyFormData.append('action', "getInputFieldsTaux");
			bodyFormData.append('id', globals.id ?? sessionStorage.getItem("id"));
			bodyFormData.append("pwd", globals.pwd);
			bodyFormData.append('auto_sf', auto_sf);
			const response = await fetch(globals.serverAddress, {
				body: bodyFormData,
				method: "POST"
			});
			const data = await response.json();
			// console.log(data);
			if(data.status && data.status == 400) {
        console.log('failed to fetch')
				return;
			}


			// console.log(marque)
			const myFomId = $(thisInput).closest('form').attr('id');
			// console.log(marque, myFomId);
			const myForm = document.getElementById(myFomId);
      let suffixe = '';
      if(myFomId == 'saleVOForm') {
        suffixe = "VO"
      }
			const tauxSelect = myForm.querySelector('#tauxFinancement'+suffixe);


			tauxSelect.innerHTML = "";
			const option = document.createElement('option');
			option.value = 0;
			option.innerHTML = "Sélectionnez le taux de financement";
			tauxSelect.prepend(option);
			data.taux?.forEach(tx => {
				const option = document.createElement('option');
				option.value = tx.Id;
				option.innerHTML = tx.Libelle;
				option.setAttribute('data-value', tx.valeur);
				tauxSelect.appendChild(option);
			})
			tauxSelect.value = fillValue;
    },
    tco: async function (thisInput = null) {
      if(typeof thisInput == 'string') {
				thisInput = document.querySelector(thisInput);
			}
			if(!thisInput) {
				return data;
			}
			const auto_t = thisInput.value;
			// console.log(thisInput)
			const bodyFormData = new FormData()
			bodyFormData.append('req', "salesController");
			bodyFormData.append('action', "getInputFieldsTCO");
			bodyFormData.append('id', globals.id ?? sessionStorage.getItem("id"));
			bodyFormData.append("pwd", globals.pwd);
			bodyFormData.append('auto_t', auto_t);
			const response = await fetch(globals.serverAddress, {
				body: bodyFormData,
				method: "POST"
			});
			const data = await response.json();
			// console.log(data);
			if(data.status && data.status == 400) {
        console.log('failed to fetch')
				return;
			}


			// console.log(marque)
			const myFomId = $(thisInput).closest('form').attr('id');
			// console.log(marque, myFomId);
			const myForm = document.getElementById(myFomId);
      let suffixe = '';
      if(myFomId == 'saleVOForm') {
        suffixe = "VO"
      }
			const tcoInput = myForm.querySelector('#tcoLibelle');
      tcoInput.value = data.tco[0].meaning;
    },
    stellantisModel(thisInput) {
      // console.log('handling stellantis...');
      let value = thisInput.value;
      let selectedOption = thisInput.querySelector(`option[value='${value}']`);
      let prepa = selectedOption.getAttribute('data-prepa-stellantis');
      let transport = selectedOption.getAttribute('data-transport-stellantis');
      // console.log('found this prepa : '+ prepa, selectedOption);
      let prepaField = document.querySelector('#prepa_advised');
      let transportField = document.querySelector('#shipping_amount');
      // console.log(transportField, transport)
      prepaField.value = prepa;
      transportField.value = transport;
    }
	},
	/**
	 * unused for now
	 * @param {String} brand 
	 */
	adaptFormToBrand: function(brand) {
		document.querySelectorAll('[data-brand]').forEach(div => {
			div.classList.add('d-none');
		})
		document.querySelectorAll(`[data-brand=${brand}`).forEach(div => {
			div.classList.remove('d-none');
		})
	},
  editParam: function(idToChange, thisBtn) {
    const input = document.querySelector(`#${idToChange}`);
    if(thisBtn.getAttribute('data-status') == 'idle') {
      input.removeAttribute('readonly');
      input.focus()
      thisBtn.setAttribute('data-status', "changing");
  
      thisBtn.style.backgroundColor = "lime";
    }
    else {
      input.setAttribute('readonly','');
      input.blur();
      thisBtn.setAttribute('data-status', "idle");
      thisBtn.style.backgroundColor = "#02356d"
    }

  },
	checkRequiredFields: function(myFormId) {
    // console.log('checkRequiredFields')
    // return true
		// let requiredArray = document.querySelectorAll(`[data-page="${globals.sales.currentPage}"] [required]`);
		let requiredArray = document.querySelectorAll(`${myFormId} [required]`);
		let check = true;
		for(let input of requiredArray) {
			if(input.tagName == "SELECT") {
				if(input.value == 0) {
					check = false;
					input.classList.add('field-error-perm');
					// setTimeout(()=>{
					// 	input.classList.remove('field-error-perm');
					// },500)
				}
        else {
          input.classList.remove('field-error-perm');
        }
			}
			else if(input.tagName == "INPUT") {
				if(input.value == '') {
					check = false;
					input.classList.add('field-error-perm');
					// setTimeout(()=>{
					// 	input.classList.remove('field-error-perm');
					// },500)
				}
        else {
          input.classList.remove('field-error-perm');
        }
			}
		}
    if(!check) {
      alert("Des champs sont invalides ou incomplets")
    }
		return check;
	},
	checkPatternsOfFields: function(myFormId) {
    // return true
		let requiredArray = document.querySelectorAll(`${myFormId} [data-page="${globals.sales.currentPage}"] [pattern]`);
		let check = true;
		for(let input of requiredArray) {
      if (input.getAttribute('pattern') !== null) {
        let pattern = '^'+input.getAttribute('pattern')+'$'
        let regexp = new RegExp(pattern, 'g')
        if (!input.value.match(regexp)) {
          check = false;
          input.classList.add('field-error');
					setTimeout(()=>{
						input.classList.remove('field-error');
					},500)
          // alert("Des champs sont invalides, vérifiez la syntaxe")
        }
        else {
          console.log(input.value, regexp, input.getAttribute('pattern') !== null)
        }
      }
		}
		return check;
	},
	setupCanvases: function() {
		const canvases = document.querySelectorAll('canvas');
		for(let canvas of canvases) {
			// console.warn(canvas);
			const canvasArrayIndex = Array.prototype.indexOf.call(canvases, canvas);
			// Initializing canvas Object in globals.canvas Array of Objects
			const canvasInit = {
				canvas: canvasArrayIndex,
				ctx: null,
				flag: false,
				prevX: 0,
				currX: 0,
				prevY: 0,
				currY: 0,
				dot_flag: false
			};
			if(!globals.canvases[canvasArrayIndex]) globals.canvases.push(canvasInit);
			globals.canvases[canvasArrayIndex].ctx = canvas.getContext("2d");
			// globals.canvases[Array.prototype.indexOf.call(canvases, canvas)].ctx = canvas.getContext("2d");
			// w = canvas.width;
			// h = canvas.height;
			canvas.addEventListener("mousemove", function (e) {
				App.findxy('move', e, canvas, 'mouse');
			}, false);
			canvas.addEventListener("mousedown", function (e) {
				App.findxy('down', e, canvas, 'mouse');
			}, false);
			canvas.addEventListener("mouseup", function (e) {
				App.findxy('up', e, canvas, 'mouse');
			}, false);
			canvas.addEventListener("mouseout", function (e) {
				App.findxy('out', e, canvas, 'mouse');
			}, false);
			canvas.addEventListener("touchstart", function (e) {
				App.findxy('down', e, canvas, 'touch');
			}, false);
			canvas.addEventListener("touchmove", function (e) {
				App.findxy('move', e, canvas, 'touch');
			}, false);
			canvas.addEventListener("touchend", function (e) {
				App.findxy('up', e, canvas, 'touch');
			}, false);
			canvas.addEventListener("touchcancel", function (e) {
				App.findxy('out', e, canvas, 'touch');
			}, false);
			// Setting Erase Buttons...
			$(canvas).closest('div').find('button').attr('data-canvas', canvasArrayIndex);
		}
	},
	findxy: function(res, e, canvas, eventType) {
		if(eventType=='touch') e.preventDefault();
		// https://gist.github.com/bencentra/91350fe91c377c1ca574
		const canvases = document.querySelectorAll('canvas');
		const currCanvas = Array.prototype.indexOf.call(canvases, canvas);
		const rect = canvas.getBoundingClientRect();
		let clientX = (eventType=='touch') ? e.touches[0].clientX  : e.clientX;
		let clientY = (eventType=='touch') ? e.touches[0].clientY : e.clientY;
		if (res == 'down') {
			globals.canvases[currCanvas].prevX = globals.canvases[currCanvas].currX;
			globals.canvases[currCanvas].prevY = globals.canvases[currCanvas].currY;
			globals.canvases[currCanvas].currX = clientX - rect.left;
			globals.canvases[currCanvas].currY = clientY - rect.top;

			globals.canvases[currCanvas].flag = true;
			globals.canvases[currCanvas].dot_flag = true;
			if (globals.canvases[currCanvas].dot_flag) {
				globals.canvases[currCanvas].ctx.beginPath();
				globals.canvases[currCanvas].ctx.fillStyle = "black";
				globals.canvases[currCanvas].ctx.fillRect(globals.canvases[currCanvas].currX, globals.canvases[currCanvas].currY, 2, 2);
				globals.canvases[currCanvas].ctx.closePath();
				globals.canvases[currCanvas].dot_flag = false;
			}
		}
		if (res == 'up' || res == "out") {
			globals.canvases[currCanvas].flag = false;
		}
		if (res == 'move') {
			if (globals.canvases[currCanvas].flag) {
				// const [x, y] = App.getTransformedPoint(clientX, clientY, canvas);
				globals.canvases[currCanvas].prevX = globals.canvases[currCanvas].currX;
				globals.canvases[currCanvas].prevY = globals.canvases[currCanvas].currY;
				globals.canvases[currCanvas].currX = clientX - rect.left;
				globals.canvases[currCanvas].currY = clientY - rect.top;
				App.draw();
			}
		}
	},
	getTransformedPoint: function (x, y, canvas) {
		const context = canvas.getContext('2d')
		const transform = context.getTransform();
		const transformedX = x - transform.e;
		const transformedY = y - transform.f;
		return [transformedX, transformedY];
	},
	draw: function () {
		const canvases = document.querySelectorAll('canvas');
		for(let canvas of canvases) {
			const currCanvas = Array.prototype.indexOf.call(canvases, canvas)
			// console.log(globals.canvases[currCanvas])
			globals.canvases[currCanvas].ctx.beginPath();
			globals.canvases[currCanvas].ctx.moveTo(globals.canvases[currCanvas].prevX, globals.canvases[currCanvas].prevY);
			globals.canvases[currCanvas].ctx.lineTo(globals.canvases[currCanvas].currX, globals.canvases[currCanvas].currY);
			globals.canvases[currCanvas].ctx.strokeStyle = "black";
			globals.canvases[currCanvas].ctx.lineWidth = 2;
			globals.canvases[currCanvas].ctx.stroke();
			globals.canvases[currCanvas].ctx.closePath();
		}
	},
	erase: function(btn) {
		const canvas = document.querySelectorAll('canvas')[Number(btn.getAttribute('data-canvas'))]
		globals.canvases[Number(btn.getAttribute('data-canvas'))].ctx.clearRect(0, 0, canvas.width, canvas.height);
	},
	setupSumGroups: async function(myFormId, action) {
    globals.sales.sumGroups = {}
		sessionStorage.setItem('currentForm', action)
		document.querySelector(myFormId).setAttribute('onsubmit', `App.${sessionStorage.getItem('currentForm')}Sale('${myFormId}maxPage')`);
		document.querySelectorAll(myFormId+ ' input, '+myFormId+' select').forEach(elem => {
			// console.log(elem);
			elem.addEventListener('keyup', event => {
				App.handleMaths(event, myFormId);
				App.refreshCalcs(myFormId);
			})
			elem.addEventListener('change', event => {
				App.handleMaths(event, myFormId);
			})
			elem.addEventListener('click', event => {
				App.handleMaths(event, myFormId);
				App.refreshCalcs(myFormId);
			})
		});
		await App.fields.required(myFormId);
		App.changeSaleFormPage(myFormId, 0, 1);
		App.setupCanvases();
		let oldMax = 0;
		document.querySelectorAll(myFormId+' div[data-page]').forEach(elem => {
			let currPage = Number(elem.getAttribute('data-page'));
			if(currPage > oldMax) {
				oldMax = currPage;
			}
		})
		globals.sales.maxPage = oldMax

    document.getElementById('date').setAttribute('min', new Date().toISOString().split('T')[0])
	},
	handleMaths: function(event, myFormId) {
		// console.log(globals.sales.sumGroups);
		const sumGroup = event.target.getAttribute('data-sum-group')?.trim().split(/\s+/);
		const sumGroupResult = event.target.getAttribute('data-sum-result')
		sumGroup?.forEach(group => App.doTheMaths(Number(group), myFormId));
		App.doTheMaths(sumGroupResult, myFormId);
	},
	refreshCalcs: function(myFormId){
		let maxGroup = 0;
    let allSumGroups = []
		const everyElemWithSumGroup = document.querySelectorAll(`${myFormId} [data-sum-group]`);
    // console.log(everyElemWithSumGroup)
    everyElemWithSumGroup.forEach(elem => {
      if(!elem.getAttribute('data-sum-group').includes(' ')) { // it's horrendous i have to do this to handle solo sumgroups
        allSumGroups.push(elem.getAttribute('data-sum-group'));
      }
			let maxSumGroup = Number(elem.getAttribute('data-sum-group').trim().split(/\s+/).reduce((a, b) => {
        // console.log("currently reading : ", a, b);
        allSumGroups.push(a)
        allSumGroups.push(b)
				if(a>b) return a;
				return b;
			}))
			maxGroup = maxSumGroup > maxGroup ? maxSumGroup : maxGroup;
		})
    allSumGroups = allSumGroups.filter((item, index) => allSumGroups.indexOf(item) === index);


    // change loop here with foreach allsumgroups
    allSumGroups.forEach(sumGroup => {
      App.doTheMaths(sumGroup, myFormId);
    })
		// for(let i=1; i<=maxGroup; i++) {
		// 	App.doTheMaths(i);
		// }

		App.handleConditions()
	},
	queryAllSumGroup: function (sumGroup, myFormId) {
		let realArray = [];
		document.querySelectorAll(`${myFormId} [data-sum-group]`).forEach(elem => {
			if(elem.getAttribute('data-sum-group').trim().split(/\s+/).includes(sumGroup.toString())) realArray.push(elem);
		})
		return realArray;
	},
	doTheMaths: function (sumGroup, myFormId) {
		if(sumGroup == null) return;
		const sumGroupArray = App.queryAllSumGroup(sumGroup, myFormId)
    // console.log(sumGroupArray);
		sumGroupArray.forEach(elem => {
      // console.log(elem);
			const index = Array.prototype.indexOf.call(sumGroupArray, elem)
			// if(sumGroup == 7) {
			// 	console.log(sumGroupArray);
			// 	console.log(index);
			// }
			if(index != -1){
				if(!globals.sales.sumGroups[`group${sumGroup}`]) {
					globals.sales.sumGroups[`group${sumGroup}`] = [];
				}
				if(!globals.sales.sumGroups[`group${sumGroup}`][index]) {
					globals.sales.sumGroups[`group${sumGroup}`][index] = {};
				}
				if(elem.getAttribute('data-sum-operator')) {
					globals.sales.sumGroups[`group${sumGroup}`][index].num = (elem.querySelector(`[value='${elem.value}']`)?.getAttribute('data-value') ?? elem.value) || 0;
					globals.sales.sumGroups[`group${sumGroup}`][index].operator = elem.getAttribute('data-sum-operator').trim().split(/\s+/)[elem.getAttribute('data-sum-group').trim().split(/\s+/).indexOf(sumGroup.toString())];
				}
			}
			if(elem.getAttribute(`data-sum-modifier-${sumGroup}`)) {
				App.modifySelfMath(elem, sumGroup, index, myFormId);
			}
		})
		App.displayMathResult(sumGroup, myFormId);
	},
	displayMathResult: function(sumGroup, myFormId) {
		const resultInputs = document.querySelectorAll(`${myFormId} [data-sum-result='${sumGroup}']`);
		resultInputs.forEach(resultInput => {
			if(resultInput != null) {
				if(!globals.sales.lockedElements.includes(resultInput.id)) {
					globals.sales.lockedElements.push(resultInput.id)
					App.refreshCalcs(myFormId);
				}
				let finalResult = globals.sales.sumGroups[`group${sumGroup}`]?.reduce((a, b) => {
					let result = 0;
					if(!isNaN(a)) {
						result = a + globals.sales.maths[b.operator]?.(Number(b.num)) ?? 0
					}
					else {
						result = globals.sales.maths[a.operator]?.(Number(a.num)) ?? 0 + globals.sales.maths[b.operator]?.(Number(b.num)) ?? 0;
					}
					return result;
				}, 0);
        globals.sales.sumGroups[`group${sumGroup}`].totalValue = finalResult;
				// resultInput.value = Math.round(finalResult * 100) / 100;

        resultInput.value = finalResult;
        // resultInput.value = finalResult.toFixed(2);
        // console.log(finalResult)
				if(resultInput.getAttribute(`data-sum-modifier-${sumGroup}`)) {
					App.modifySelfMath(resultInput, sumGroup, -1, myFormId)
				}
			}
		})
	},
	modifySelfMath: function(elem, sumGroup, index, myFormId) {
    // console.log(elem, sumGroup, index)
		let oldVal = elem.value;
		let newVal;
		const operand = elem.getAttribute(`data-sum-modifier-operand-${sumGroup}`).trim().split(/\s+/);
		const modifiers = elem.getAttribute(`data-sum-modifier-${sumGroup}`).trim().split(/\s+/);
		if(operand.length != modifiers.length || operand.length == 0) {
			console.error("missing operand or modifiers");
			return;
		}
		for(let i=0; i<operand.length; i++) {
			if(isNaN(operand[i])) {
				const operandElem = document.querySelector(operand[i]);
				const modifierIndex = Array.prototype.indexOf.call(App.queryAllSumGroup(sumGroup, myFormId), operandElem);
				newVal = globals.sales.maths[modifiers[i]]?.(oldVal, globals.sales.sumGroups[`group${sumGroup}`]?.[modifierIndex]?.num ?? operandElem.getAttribute('data-value') ?? operandElem.querySelector(`[value='${operandElem.value}']`)?.getAttribute('data-value') ?? operandElem.value);
				if(index != -1) {
					globals.sales.sumGroups[`group${sumGroup}`][index].num = newVal;
				}
			}
			else {
				newVal = globals.sales.maths[modifiers[i]]?.(oldVal, Number(operand[i]));
				if(index != -1) {
					globals.sales.sumGroups[`group${sumGroup}`][index].num = newVal;
				}
			}
			oldVal = newVal;
      globals.sales.sumGroups[`group${sumGroup}`].modifiedVal = newVal; // for debug purposes
		}

		if(elem.getAttribute('data-sum-modifier-type') == 'visual' || index == -1) {
			elem.value = Number(newVal)
      if(!isNaN(Number(newVal))) {
        // elem.value = Math.round(Number(newVal) * 100) / 100;
        // elem.value = Number(newVal).toFixed(2);
        elem.value = Number(newVal);
      }
      else {
        console.warn("can't do it: ", newVal)
      }
		}
	},
	handleConditions: function() {
    // try {
    //   document.querySelectorAll('[data-condition]').forEach(elem => {
    //     console.log(elem.id, elem.getAttribute('data-condition').trim().split(/[\(\)]/).filter(item => (item != "" && item != undefined)).map(item => item.trim().split(/\s+/)).map(array => array.map(item => item[0] == "#" ? (document.querySelector(item)?.getAttribute('data-value') ?? document.querySelector(item)?.value) || 0 : item)).map(array => array.reduce((a, b) => ((a == "!" ? "!=" : (/[A-Za-z]/.test(a) ? (/'/.test(a) ? a : "'" + a + "'") : a)) + " " + (b == "!" ? "!=" : (/[A-Za-z]/.test(b) ? (/'/.test(b) ? b : "'" + b + "'") : b))), "").trim()).reduce((a,b) => ((a == "!" ? "!=" : (/[A-Za-z]/.test(a) ? (/'/.test(a) ? a : "'" + a + "'") : a)) + " " + (b == "!" ? "!=" : (/[A-Za-z]/.test(b) ? (/'/.test(b) ? b : "'" + b + "'") : b))), "").trim())
    //   });
    // }
    // catch(e) {

    // }
    
		document.querySelectorAll('[data-condition]').forEach(elem => elem.value = eval(elem.getAttribute('data-condition').trim().split(/[\(\)]/).filter(item => (item != "" && item != undefined)).map(item => item.trim().split(/\s+/)).map(array => array.map(item => item[0] == "#" ? (document.querySelector(item)?.getAttribute('data-value') ?? document.querySelector(item)?.value) || 0 : item)).map(array => array.reduce((a, b) => ((a == "!" ? "!=" : (/[A-Za-z]/.test(a) ? (/'/.test(a) ? a : "'" + a + "'") : a)) + " " + (b == "!" ? "!=" : (/[A-Za-z]/.test(b) ? (/'/.test(b) ? b : "'" + b + "'") : b))), "").trim()).reduce((a,b) => ((a == "!" ? "!=" : (/[A-Za-z]/.test(a) ? (/'/.test(a) ? a : "'" + a + "'") : a)) + " " + (b == "!" ? "!=" : (/[A-Za-z]/.test(b) ? (/'/.test(b) ? b : "'" + b + "'") : b))), "").trim()) ? (elem.getAttribute('data-condition-value-true')[0] == "#" ? document.querySelector(elem.getAttribute('data-condition-value-true')).value : elem.getAttribute('data-condition-value-true')) : (elem.getAttribute('data-condition-value-false')[0] == "#" ? document.querySelector(elem.getAttribute('data-condition-value-false')).value : elem.getAttribute('data-condition-value-false')));
		App.handleMarge()
    /*
		document.querySelectorAll('[data-condition]').forEach(function (elem) {
			return elem.value = eval(elem.getAttribute('data-condition').trim().split(/[\(\)]/).filter(function (item) {
				return item != "" && item != undefined;
			}).map(function (item) {
				return item.trim().split(/\s+/);
			}).map(function (array) {
				return array.map(function (item) {
					return item[0] == "#" ? (document.querySelector(item)?.getAttribute('data-value') ?? document.querySelector(item)?.value) || 0 : item;
				});
			}).map(function (array) {
				return array.reduce(function (a, b) {
					return (a == "!" ? "!=" : /[A-Za-z]/.test(a) ? /'/.test(a) ? a : "'" + a + "'" : a) + " " + (b == "!" ? "!=" : /[A-Za-z]/.test(b) ? /'/.test(b) ? b : "'" + b + "'" : b);
				}, "").trim();
			}).reduce(function (a, b) {
				return (a == "!" ? "!=" : /[A-Za-z]/.test(a) ? /'/.test(a) ? a : "'" + a + "'" : a) + " " + (b == "!" ? "!=" : /[A-Za-z]/.test(b) ? /'/.test(b) ? b : "'" + b + "'" : b);
			}, "").trim()) ? elem.getAttribute('data-condition-value-true')[0] == "#" ? document.querySelector(elem.getAttribute('data-condition-value-true')).value : elem.getAttribute('data-condition-value-true') : elem.getAttribute('data-condition-value-false')[0] == "#" ? document.querySelector(elem.getAttribute('data-condition-value-false')).value : elem.getAttribute('data-condition-value-false');
		});
		*/
	},
  handleMarge: function(input) {
    if(!input) {
      input = document.getElementById('margeInfo');
    }
    if(input.value == "OK, Marge Correcte") {
      input.style.backgroundColor = "green";
      input.style.color = "white";
    }
    else if(input.value == "Marge trop faible") {
      input.style.backgroundColor = "red";
      input.style.color = "white";
    }
    else {
      input.style.backgroundColor = "initial";
      input.style.color = "initial";
    }
  },
	change1stPassword: function(form) {
		const pwd = form.querySelector('#newPwd');
		const pwd1 = form.querySelector('#newPwd1');
		if(pwd.value != pwd1.value) {
			pwd.classList.add('field-error');
			pwd1.classList.add('field-error');
			setTimeout(() => {
				pwd.classList.remove('field-error');
				pwd1.classList.remove('field-error');
			}, 500);
			return;
		}
		const query = "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=usersController&action=change1stPassword&newPwd=" + pwd.value;
		$.post(globals.serverAddress,
			query, function(data){
				data = JSON.parse(data);
				if(data.status == 200) {
					App.closeModal('#changePwdModal')
				}
		});
	},
	duplicateField: function(elem) {
		const clone = elem.parentNode.cloneNode();
		clone.innerHTML = elem.parentNode.innerHTML;
		clone.querySelector('input').id += '-';
		elem.parentNode.parentNode.insertBefore(clone, elem.parentNode.nextSibling);
		elem.remove();
	},
	readyRecap: function(myFormId) {
		document.querySelectorAll(`${myFormId} [data-recap]`).forEach(elem => {
			// console.log(elem);
			// console.log(elem.tagName);
			if(elem.tagName == "INPUT") {
        if(elem.getAttribute('type') == "date") {
          document.querySelector(elem.getAttribute('data-recap')).value = new Date(elem.value).toLocaleDateString('FR-fr')
        }
        else {
          if(document.querySelector(elem.getAttribute('data-recap'))) {
            document.querySelector(elem.getAttribute('data-recap')).value = elem.value
          }
          else {
            console.error('missing recap Id : ' + elem.getAttribute('data-recap'))
          }
        }
			}
			else if(elem.tagName == "SELECT") {
        try {
          document.querySelector(elem.getAttribute('data-recap')).value = elem.querySelector('option[value="'+elem.value+'"]').innerHTML.includes('Sélectionnez') ? "-" : elem.querySelector('option[value="'+elem.value+'"]').innerHTML;
        }
        catch(e) {
          console.log(elem.getAttribute('data-recap'), document.querySelector(elem.getAttribute('data-recap')))
        }
			}
		})
	},
	changeSaleFormPage: function (myFormId, step = 1, setPage = 1) {
		if(step > 0) {
			const check = App.checkPatternsOfFields(myFormId);
			if(!check) return;
		}
		if(globals.sales.currentPage > 7 && step == 1) {
			const check = App.checkRequiredFields(myFormId);
			if(!check) return;
		}
		const myForm = document.body.querySelector(myFormId);
		const myModal = document.body.querySelector('#'+$(myFormId).closest('.modal').attr('id'));
		const myContainer = myModal ?? document.body.querySelector('#'+$(myFormId).closest('div').attr('id')); // if not in Modal then we use the form parent div
		if(step > 0) {
			myForm.querySelector(`${myFormId} div[data-page='${globals.sales.currentPage}']`).classList.add('d-none');
			globals.sales.currentPage += globals.sales.currentPage < globals.sales.maxPage ? step : App.addSale(myFormId);
			myForm.querySelector(`div[data-page='${globals.sales.currentPage}']`).classList.remove('d-none');
		}
		else if(step < 0) {
			myForm.querySelector(`${myFormId} div[data-page='${globals.sales.currentPage}']`).classList.add('d-none');
			globals.sales.currentPage += globals.sales.currentPage > 1 ? step : 0;
			myForm.querySelector(`${myFormId} div[data-page='${globals.sales.currentPage}']`).classList.remove('d-none');
		}
		else if(step == 0 && setPage < globals.sales.maxPage) {
			myForm.querySelector(`${myFormId} div[data-page='${globals.sales.currentPage}']`).classList.add('d-none');
			globals.sales.currentPage = setPage;
			myForm.querySelector(`${myFormId} div[data-page='${globals.sales.currentPage}']`).classList.remove('d-none');
		}
		// console.log(myContainer, globals.sales.currentPage);
    let btn;
    if(myFormId == "#saleForm") {
      btn = myContainer.querySelector('#saleFormNext');
      if(globals.sales.currentPage == 1) myContainer.querySelector('#saleFormTitle').innerHTML = "Etape 1/9 : Infos client" //1
      if(globals.sales.currentPage == 2) myContainer.querySelector('#saleFormTitle').innerHTML = "Etape 2/9 : Infos véhicule vendu" //2
      if(globals.sales.currentPage == 3) myContainer.querySelector('#saleFormTitle').innerHTML = "Etape 3/9 : Infos véhicule repris" //3
      if(globals.sales.currentPage == 4) myContainer.querySelector('#saleFormTitle').innerHTML = "Etape 4/9 : Tarifs et prix de revient" //4
      if(globals.sales.currentPage == 5) myContainer.querySelector('#saleFormTitle').innerHTML = "Etape 5/9 : Aide Remise et commisions" //5
      if(globals.sales.currentPage == 6) myContainer.querySelector('#saleFormTitle').innerHTML = "Etape 6/9 : Produits Complémentaires Facultatifs au tarif normal" //6
      if(globals.sales.currentPage == 7) myContainer.querySelector('#saleFormTitle').innerHTML = "Etape 7/9 : Financement" //7
      if(globals.sales.currentPage == 8) myContainer.querySelector('#saleFormTitle').innerHTML = "Etape 8/9 : Accessoires" //8
      if(globals.sales.currentPage == 9) myContainer.querySelector('#saleFormTitle').innerHTML = "<div class='w-100 d-flex justify-content-between align-items-center'><span>Etape 9/9 : Récapitulatif</span> <button onclick='App.printDivWithVals(`saleFormBody`, `saleModal`);' class='btn btn-primary'><i class='fa-solid fa-print'></i></button></div>" //9
      if(globals.sales.currentPage == 9) App.readyRecap(myFormId);
    }
    else {
      btn = myContainer.querySelector('#saleVOFormNext');
      if(globals.sales.currentPage == 1) myContainer.querySelector('#saleVOFormTitle').innerHTML = "Etape 1/7 : Infos client" //1
      if(globals.sales.currentPage == 2) myContainer.querySelector('#saleVOFormTitle').innerHTML = "Etape 2/7 : Infos véhicule vendu" //2
      if(globals.sales.currentPage == 3) myContainer.querySelector('#saleVOFormTitle').innerHTML = "Etape 3/7 : Infos véhicule repris" //3
      if(globals.sales.currentPage == 4) myContainer.querySelector('#saleVOFormTitle').innerHTML = "Etape 4/7 : Tarifs" //4
      if(globals.sales.currentPage == 5) myContainer.querySelector('#saleVOFormTitle').innerHTML = "Etape 5/7 : Complémentaires" //5
      if(globals.sales.currentPage == 6) myContainer.querySelector('#saleVOFormTitle').innerHTML = "Etape 6/7 : Financement" //6
      if(globals.sales.currentPage == 7) myContainer.querySelector('#saleVOFormTitle').innerHTML = "<div class='w-100 d-flex justify-content-between align-items-center'><span>Etape 7/7 : Récapitulatif</span> <button onclick='App.printDivWithVals(`saleVOForm`, `saleVOModal`);' class='btn btn-primary'><i class='fa-solid fa-print'></i></button></div>" //7
      if(globals.sales.currentPage == 7) App.readyRecap(myFormId);
    }




		if(globals.sales.currentPage == globals.sales.maxPage) {
			btn.classList.add('btn-success');
			btn.classList.remove('btn-primary');
			btn.innerHTML = 'Valider';
			btn.setAttribute('type',"submit");
			btn.setAttribute("data-bs-dismiss","modal");
			// $(btn).attr('onclick', `App.addSale(${myForm})`);
			// btn.addEventListener('click', App.addSale(myForm));
		}
		else {
			btn.classList.remove('btn-success');
			btn.classList.add('btn-primary');
			btn.innerHTML = 'Suivant <i class="fa fa-arrow-circle-right ms-1"></i>';
			btn.setAttribute('type',"button");
			btn.removeAttribute("data-bs-dismiss");
			// btn.removeEventListener('click', App.addSale(myForm));
		}
	},
	checkAccountStatus: function () {
		$.post(globals.serverAddress, {id: $.localStorage.getItem('id'), login: $.localStorage.getItem('login'), pwd: $.localStorage.getItem('pwd'), req: 'checkAccountStatus'}, function(data){
			if(data.check!="GO") {
				App.logMeOut();
				alert("Il y a un problème avec votre compte, veuillez réessayer plus tard\r\nSi vous pensez qu'il s'agit d'une erreur, veuillez nous contacter.");
			}
			else {
				// Every things is ok so we refresh globals and we kick things off...
				$.localStorage.setItem('login', data.login);
				$.localStorage.setItem('pwd', data.pwd);
				$.localStorage.setItem('name', data.name);
				$.localStorage.setItem('firstname', data.firstname);
				$.localStorage.setItem('phone', data.phone);
				$.localStorage.setItem('email', data.email);
				$.localStorage.setItem('id', data.id);
				$.localStorage.setItem('active', data.active);
				$.localStorage.setItem('type', data.type);
				$.localStorage.setItem('mails', data.mails);
				$.localStorage.setItem('phone2', data.phone2);
				$.localStorage.setItem('address', data.address);
				$.localStorage.setItem('address2', data.address2);
				$.localStorage.setItem('zip', data.zip);
				$.localStorage.setItem('city', data.city);
				$.localStorage.setItem('country', data.country);
				$.localStorage.setItem('wpid', data.wpid);
				$.localStorage.setItem('birthdate', data.birthdate);
				$.localStorage.setItem('job', data.job);
				$.localStorage.setItem('avatar', data.avatar);
				$.localStorage.setItem('initials', data.initials);
				$.localStorage.setItem('comments', data.comments);
				if(!globals.changeSite) $.sessionStorage.setItem('site', data.site);
				else data.site = globals.site; // have to set changeSite selectBox value there before refreshGlobals call
				// console.log("checkAccountStatus: "+globals.site);
				globals.adminPass = data.adminPass;

				if(data.mustChangePwd == 1) {
					const myModal = new bootstrap.Modal(document.getElementById('changePwdModal'), {
						keyboard: false,
						backdrop: 'static',
						focus: true
					})
					myModal.show();
				}
				setTimeout(function(){
					App.refreshGlobals(data);
				}, 100);
				setTimeout(function(){
					App.kickThingsOffOnceLoggedIn();
				}, 200);
			}
		}, "json").fail(function(){
			App.logMeOut();
			alert("Suite à un problème technique nous ne pouvons vérifier votre compte, veuillez réessayer plus tard\r\nSi l'erreur persiste, veuillez nous contacter.");
		});
	},
	closeModal: function(modalDiv) {
		const myModal = document.querySelector(modalDiv);
		if(modalDiv == '#saleModal') {
      App.clearFormFields('#saleForm');
      globals.sales.sumGroup = {};
    }
		// console.log(myModal)
		myModal.style.display = "none";
		myModal.classList.remove('show');
		document.querySelector('.modal-backdrop')?.remove();
	},
	logMeIn: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		$(myFormDiv).closest('.row').css('opacity', 0.6);
		let query = $(myFormDiv).serialize();
		const req = "login";
		query = query + "&req=" + req;
		$.post(globals.serverAddress, query, function(data){
			if(data.pass == "OK") {
				$.localStorage.setItem('pass', data.pass);
				$.localStorage.setItem('login', data.login);
				$.localStorage.setItem('pwd', data.pwd);
				$.localStorage.setItem('name', data.name);
				$.localStorage.setItem('firstname', data.firstname);
				$.localStorage.setItem('phone', data.phone);
				$.localStorage.setItem('email', data.email);
				$.localStorage.setItem('id', data.id);
				$.localStorage.setItem('active', data.active);
				$.localStorage.setItem('type', data.type);
				$.localStorage.setItem('mails', data.mails);
				$.localStorage.setItem('phone2', data.phone2);
				$.localStorage.setItem('address', data.address);
				$.localStorage.setItem('address2', data.address2);
				$.localStorage.setItem('zip', data.zip);
				$.localStorage.setItem('city', data.city);
				$.localStorage.setItem('country', data.country);
				$.localStorage.setItem('wpid', data.wpid);
				$.localStorage.setItem('birthdate', data.birthdate);
				$.localStorage.setItem('job', data.job);
				$.localStorage.setItem('avatar', data.avatar);
				$.localStorage.setItem('initials', data.initials);
				$.localStorage.setItem('comments', data.comments);
				if(!globals.changeSite) $.sessionStorage.setItem('site', data.site);
				globals.adminPass = data.adminPass;
				setTimeout(function(){
					App.refreshGlobals(data);
				}, 100);
				setTimeout(function(){
					document.location.href='./';
				}, 1000);
			}
			else {
				if(data.active==0) alert("Ce compte a été désactivé !");
				else alert("Identifiant ou mot de passe erroné !");
			}
		}, "json").always(function() {
			$(myFormDiv+' #sender').attr("disabled", false).html('<b><i class="fa fa-unlock"></i> Connexion</b>');
			$(myFormDiv).closest('.row').css('opacity', 1);
		});
	},
	logMeOut: function () {
		$.localStorage.removeItem('pass');
		$.localStorage.removeItem('login');
		$.localStorage.removeItem('pwd');
		$.localStorage.removeItem('name');
		$.localStorage.removeItem('firstname');
		$.localStorage.removeItem('phone');
		$.localStorage.removeItem('id');
		$.localStorage.removeItem('email');
		$.localStorage.removeItem('active');
		$.localStorage.removeItem('type');
		$.localStorage.removeItem('mails');
		$.localStorage.removeItem('phone2');
		$.localStorage.removeItem('address');
		$.localStorage.removeItem('address2');
		$.localStorage.removeItem('zip');
		$.localStorage.removeItem('city');
		$.localStorage.removeItem('country');
		$.localStorage.removeItem('wpid');
		$.localStorage.removeItem('birthdate');
		$.localStorage.removeItem('avatar');
		$.localStorage.removeItem('initials');
		$.localStorage.removeItem('comments');
		$.sessionStorage.removeItem('site');
		$.sessionStorage.removeItem('changeSite');
		setTimeout(function(){
			document.location.href='/login.html';
			// document.location.href='https://gpi.snsolutions.fr';
		}, 1000);
	},
	subContact: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		let query = $(myFormDiv).serialize();
		const req = "contact";
		query = query + "&req=" + req;
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok")
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-2x fa-check-circle"></i>&nbsp;Votre message a bien été envoyé.</b></div>';
			else
				returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-2x fa-times-circle"></i>&nbsp;Votre message n\'a pas été envoyé.</b></div>';
			$(myFormDiv+' #sender').attr("disabled", false).html('Envoyer&nbsp;<i class="fa fa-paper-plane"></i>');
			$(myFormDiv+' #successfail').empty().append(returns);
		}, "json");
	},
	addWasValidatedClass: function (myFormDiv) {
		$(myFormDiv).addClass('was-validated');
		var wrongFields = "Les champs suivants sont mal renseignés: \n";
		var wrongPop = false;
		$(myFormDiv).find(':invalid').each(function () {
			//alert("IN");
			wrongPop = true;
			$(this).closest('.form-group').find('label').not('.notValidatedClass').each(function () {
				wrongFields += $(this).text()+"\n";
			});
		});
		if(wrongPop) {
			alert(wrongFields);
			if(myFormDiv=='#modInterventionForm') $(myFormDiv+' #addActionForm').slideDown();
		}
		return !wrongPop;
	},
	clearFormFields: function(myFormDiv, event) {
		// if(myFormDiv=='#editPicturesAddressForm') event.preventDefault(); // prevents form submission when button is inside it !
		$(myFormDiv).find("input.form-control[type=text], input.form-control[type=tel], input.form-control[type=email], input.form-control[type=number], input.form-control[type=file], input.form-control[type=password], textarea.form-control, select.form-select").val('');
		$(myFormDiv).find("input[type=checkbox]").prop("checked", false);
		$(myFormDiv).find("input[type=radio]").prop("checked", false);
		$(myFormDiv).removeClass('was-validated');
		$(myFormDiv+' #successfail').empty();
		$(myFormDiv+' #successfailTop').empty();
	},
	safeJsonParse: function(input) {
		try {
			return JSON.parse(input);
		} catch (e) {
			return undefined;
		}
	},
	urlParam: function(name, url) {
		// Get parameters from an URL
		var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
		//For current URL
		//var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
		if (results==null){
			return null;
		}
		else{
			return results[1] || 0;
		}
	},
	bindUIActions: function() {
		// Connected or not
		if(globals.pass == "OK") {
			App.checkAccountStatus();
			App.kickThingsOffOnceLoggedIn();
		}
		// Is it Mobile devicebindUIActions
		if(/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent) || window.innerWidth<992) globals.isMobile = true;
		if(globals.isMobile) {
			$('#myTab').removeClass('nav-tabs').addClass('nav-pills');
		}
		$('.expends').on("click", function () {
			$(this).next('div').slideToggle('slow');
			//$.mobile.silentScroll($(this).next('div').offset().top);
		});

		// Managing navigation so that we bindUIActions on the right page...
		let url = window.location.pathname;
		globals.myPage = url.substring(url.lastIndexOf('/')+1);
		$.sessionStorage.setItem('myPage', globals.myPage);
		// Smooth Scroll to div...
		App.refreshSmoothScroll();
		// Make Mobile navigation smoother by closing menu but only when a new page or modal is shown (not on collapse links)
		$('.nav-link').not('[data-bs-toggle="collapse"]').on('click', function () {
			$('body').removeClass('sidenav-toggled');
		});
		// Litepicker Documentation : https://wakirin.github.io/Litepicker
		const litepickerRangePlugin = document.getElementById('litepickerRangePlugin');
		if (litepickerRangePlugin) {
			new Litepicker({
				element: litepickerRangePlugin,
				startDate: new Date(),
				endDate: new Date(),
				singleMode: false,
				numberOfMonths: 2,
				numberOfColumns: 2,
				format: 'MMM DD, YYYY',
				lang: 'fr-FR',
				plugins: ['ranges'],
				ranges: {
					customRangesLabels:["Aujourd'hui","Hier","Derniers 7 Jours","Derniers 30 jours","Ce Mois","Dernier Mois"],
				},
			});
		}
		/*
		const litepickerSingleDate = document.getElementById('litepickerSingleDate');
		if (litepickerSingleDate) {
			new Litepicker({
				element: litepickerSingleDate,
				format: 'MMM DD, YYYY',
				lang: 'fr-FR',
			});
		}
		const litepickerDateRange = document.getElementById('litepickerDateRange');
		if (litepickerDateRange) {
			new Litepicker({
				element: litepickerDateRange,
				singleMode: false,
				format: 'MMM DD, YYYY',
				lang: 'fr-FR',
			});
		}
		const litepickerDateRange2Months = document.getElementById('litepickerDateRange2Months');
		if (litepickerDateRange2Months) {
			new Litepicker({
				element: litepickerDateRange2Months,
				singleMode: false,
				numberOfMonths: 2,
				numberOfColumns: 2,
				format: 'MMM DD, YYYY',
				lang: 'fr-FR',
			});
		}
		*/
		document.addEventListener("scroll", function (event) {
			if (App.getDocHeight() == App.getScrollXY()[1] + window.innerHeight) {
				//$('.go-up-fixed').fadeOut('slow');
				globals.bottomScrolled=true;
			}
			else {
				globals.bottomScrolled=false;
				if(App.getScrollXY()[1] == 0) {
					$('.go-up-fixed').fadeOut('slow');
				}
				else
					$('.go-up-fixed').fadeIn('slow');
			}
		});
		/*
		$('#addBordereauxModal #date').datepicker({
			locale: 'fr-fr',
			format: 'dd/mm/yyyy',
			value: globals.today.toLocaleDateString('fr-FR'),
			minDate: globals.today,
			maxDate: globals.today,
			modal: true,
			showOtherMonths: true,
			iconsLibrary: 'fontawesome',
			uiLibrary: 'bootstrap4'
			//uiLibrary: 'bootstrap'
		});
		// On Enter search...
		$('#keywords').keydown(function(e) {
			var keywords = $('#keywords').val();
			if (e.keyCode === 13) {
				App.search();
			}
		});
		// Continuous search...
		$('#keywords').keyup(function() {
			var keywords = $('#keywords').val();
			if(keywords.length > 6) {
				App.search();
			} else {
				// doNothing !
			}
		});
		*/
	},
	refreshMenuDisplay: function() {
		switch (globals.type) {
			case 'MyBelovedLord':
				$(".adminOnly").show();
				$(".adminOnlyFlex").css('display', 'flex');
				$(".adminHide").hide();
			break;
			case 'manager':
				$(".managerOnly").show();
				$(".managerOnlyFlex").css('display', 'flex');
				$(".managerHide").hide();
				$('.allUsersRemove').remove();
			break;
			case 'accountant':
				$(".accountantOnly").show();
				$(".accountantOnlyFlex").css('display', 'flex');
				$(".accountantHide").hide();
				$('.allUsersRemove').remove();
			break;
			case 'user':
				$(".userOnly").show();
				$(".userOnlyFlex").css('display', 'flex');
				$(".userHide").hide();
				$('.allUsersRemove').remove();
			break;
			default:
				$(".userOnly").show();
				$(".userOnlyFlex").css('display', 'flex');
				$(".userHide").hide();
				$('.allUsersRemove').remove();
				// $('.usersDisabledOptions').hide().prop("disabled", true); // Display none or hide won't work in Safari but disable works allUsersRemove
		}
	},
	kickThingsOffOnceLoggedIn: function() {
		// Display .adminOnly, .accountantOnly... Objects
		App.refreshMenuDisplay();
		App.getSitesListBox();
		if(globals.avatar != '') $("#avatarNavCont").html('<img class="img-fluid" src="'+globals.serverBaseUrl+'docs/avatars/'+globals.avatar+'" />');
		else $("#avatarNavCont").html('<span class="dropdown-user-img text-center fs-2 text-white">'+globals.initials+'</span>');
		$("#userInitialsNav").html(globals.initials);
		$(".profile-links").attr("href", "/users/"+globals.id);
		$('.my-account-name').empty().append(globals.name+' '+globals.firstname);
		$('.my-account-mail').empty().append(globals.email);
		$('#modal_contact #from_name').val(globals.name+' '+globals.firstname);
		$('#modal_contact #from_email').val(globals.email);
		$('#modal_contact #from_phone').val(globals.phone);
		// this.buildMyDropzone('#addInterventionForm', App.addIntervention, 'interventionsController', 'addIntervention', true);
		// this.buildMyDropzone('#modInterventionForm', App.modIntervention, 'interventionsController', 'modIntervention', true);
		/*
		const goTo = App.urlParam('goto', document.URL); // Where are we going
		const idBord = App.urlParam('id', document.URL); // Then get the id
		if(goTo == 1) App.fillModBordereaux(idBord, $("#modBordereauxBtn_"+idBord), '#modBordereauxForm', '#modBordereauxModal');
		if(globals.isMobile) $('#cotisationFramePop').closest('.embed-responsive').removeClass('embed-responsive-16by9').addClass('embed-responsive-1by1');
		if(goTo == 'pay') {
			// $('#cotisationFramePop').attr('src', 'https://gpi.snsolutions.fr/bo/pay/paypage.php?auto_u='+globals.id);
			if($('#cotisationFramePop').length) {
				const cotisationFramePop = document.getElementById('cotisationFramePop');
				cotisationFramePop.src += '?auto_u='+globals.id;
			}
			let myModal = new bootstrap.Modal(document.getElementById('cotisationModal'));
			myModal.show();
		}
		*/
	},
	getSitesListBox: function() {
		const req = "navigationController";
		const action = "getSitesListBox";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action;
		$.post(globals.serverAddress, query, function(data){
			$("#siteGlobal").empty().append(data.snippet); // In sidebar
			$("#addUserForm #site_u").empty().append(data.snippet); // In addUserModal
		}, "json").done(function(){
			if(globals.changeSite || globals.changeSite===null) $('#siteGlobal').val(globals.site);
			// console.log('option[value="'+document.querySelector('#siteGlobal').value+'"]')
			// document.querySelector('option[value="'+document.querySelector('#siteGlobal').value+'"]').innerHTML;
			$('#siteDisplay').val(document.querySelector('option[value="'+document.querySelector('#siteGlobal').value+'"]').innerHTML);
			console.log("getSitesListBox: "+globals.changeSite+" - "+globals.site);
		}).always(function(){
		});
	},
	getMyMonthlyPrice: function(){
		const date1 = new Date();
		date1.setDate(1)
		const date2 = new Date();
		const req = "dashController";
		const action = "getMyMonthlyPrice";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action + "&date1=" + date1.toISOString().substring(0, 10) + "&date2=" + date2.toISOString().substring(0, 10);
		$.post(globals.serverAddress, query, function(data){
			$("#myMonthlyPrice").empty().append(data.myMonthlyPrice + ' €'); // In sidebar
		}, "json").done(function(){
		}).always(function(){
		});
	},
	getMyAnnualPrice: function(){
		const date1 = new Date();
		date1.setMonth(0)
		date1.setDate(1);
		const date2 = new Date();
		const req = "dashController";
		const action = "getMyAnnualPrice";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action + "&date1=" + date1.toISOString().substring(0, 10) + "&date2=" + date2.toISOString().substring(0, 10);
		$.post(globals.serverAddress, query, function(data){
			$("#myAnnualPrice").empty().append(data.myAnnualPrice + ' €'); // In sidebar
		}, "json").done(function(){
		}).always(function(){
		});
	},
	getGlobalMonthlyPrice: function(){
		const date1 = new Date();
		date1.setDate(1)
		const date2 = new Date();
		const req = "dashController";
		const action = "getGlobalMonthlyPrice";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action + "&date1=" + date1.toISOString() + "&date2=" + date2.toISOString();
		$.post(globals.serverAddress, query, function(data){
			$("#globalMonthlyPrice").empty().append(data.globalMonthlyPrice + ' €'); // In sidebar
		}, "json").done(function(){
		}).always(function(){
		});
	},
	getGlobalAnnualPrice: function(){
		const date1 = new Date();
		date1.setMonth(0)
		date1.setDate(1);
		const date2 = new Date();
		const req = "dashController";
		const action = "getGlobalAnnualPrice";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action + "&date1=" + date1.toISOString().substring(0, 10) + "&date2=" + date2.toISOString().substring(0, 10);
		$.post(globals.serverAddress, query, function(data){
			$("#globalAnnualPrice").empty().append(data.globalAnnualPrice + ' €'); // In sidebar
		}, "json").done(function(){
		}).always(function(){
		});
	},
	getGlobalMonthlySales: function(){
		const date1 = new Date();
		date1.setDate(1)
		const date2 = new Date();
		const req = "dashController";
		const action = "getGlobalMonthlySales";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action + "&date1=" + date1.toISOString() + "&date2=" + date2.toISOString();
		$.post(globals.serverAddress, query, function(data){
			$("#globalMonthlySales").empty().append(data.globalMonthlySales); // In sidebar
		}, "json").done(function(){
		}).always(function(){
		});
	},
	getGlobalAnnualSales: function(){
		const date1 = new Date();
		date1.setMonth(0)
		date1.setDate(1);
		const date2 = new Date();
		const req = "dashController";
		const action = "getGlobalAnnualSales";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action + "&date1=" + date1.toISOString() + "&date2=" + date2.toISOString();
		$.post(globals.serverAddress, query, function(data){
			$("#globalAnnualSales").empty().append(data.globalAnnualSales); // In sidebar
		}, "json").done(function(){
		}).always(function(){
		});
	},
	getMyMonthlySales: function(){
		const date1 = new Date();
		date1.setDate(1)
		const date2 = new Date();
		const req = "dashController";
		const action = "getMyMonthlySales";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action + "&date1=" + date1.toISOString().substring(0, 10) + "&date2=" + date2.toISOString().substring(0, 10);
		$.post(globals.serverAddress, query, function(data){
			$("#myMonthlySales").empty().append(data.myMonthlySales); // In sidebar
		}, "json").done(function(){
		}).always(function(){
		});
	},
	getMyAnnualSales: function(){
		const date1 = new Date();
		date1.setMonth(0)
		date1.setDate(1);
		const date2 = new Date();
		const req = "dashController";
		const action = "getMyAnnualSales";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action + "&date1=" + date1.toISOString().substring(0, 10) + "&date2=" + date2.toISOString().substring(0, 10);
		$.post(globals.serverAddress, query, function(data){
			$("#myAnnualSales").empty().append(data.myAnnualSales); // In sidebar
		}, "json").done(function(){
		}).always(function(){
		});
	},
	getFormsSelectBoxes: async function(formsDivArray, myController, inputsArray) {
		let result;
		// Using try to get error handling, in order to have just the promise : const result = await $.post(...) would have done it.
		try {
			result = $.post(globals.serverAddress, {id: globals.id, type: globals.type, site: globals.site, pwd: globals.pwd, ap: globals.adminPass, req: myController, action: 'getFormsSelectBoxes'}, function(data){
				if(formsDivArray.length>0 && inputsArray.length>0) {
					formsDivArray.forEach((formDiv, indexDiv) => {
						inputsArray.forEach((input, index) => {
							$(formDiv+" #"+input).empty().append(data[input]); // Can't use data.input here
							// console.log(formDiv+" #"+input+" | "+data[input]);
						});
					});
				}
			}, "json").always(function() {
			});
			return result;
		} catch (error) {
			console.error(error);
		}
	},
	popModal: function(myModalId) {
		const myModalObject = document.getElementById(myModalId);
		if (myModalObject) {
			let myModal = new bootstrap.Modal(myModalObject, {
				backdrop: 'static'
			});
			myModal.show();
		}
		else {
			setTimeout(function() { // Little timer to let the system charge page if needed
				App.popModal(myModalId);
			}, 1000);
		}
	},
	getNavNotifications: function() {
		const req = "navigationController";
		const action = "getNavNotifications";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&ap=" + globals.adminPass + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action;
		$.post(globals.serverAddress, query, function(data){
			$("#navbarDropdownAlerts").empty().append(data.badge);
			$("#navNotificationsCont").empty().append(data.snippet);
		}, "json").always(function(){
			// Refreshing alerts every now and then...
			if(globals.type=='MyBelovedLord') {
				setTimeout(function(){
					App.getNavNotifications();
				}, 60000);
			}
		});
	},
	globalSearch: function(thisElement) {
		const search = thisElement.value;
		window.open('https://www.google.fr/search?q='+search, '_blank');
		/*
		const req = "navigationController";
		const action = "globalSearch";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action + "&search=" + search;
		$.post(globals.serverAddress, query, function(data){
			$("#globalSearchResultsCont").empty().append(data.snippet);
		}, "json").done(function(){
			App.buildDataTables('#dataTableGlobalSearchList', 25, true, 0, 'desc', 'Mozart Autos - Liste des Résultats de la recherche', [0,3,4,5,6,7,8,9,10,11], [1,2,12], '', '', '', [0,1,2,3,4,5,6,7,8,9,10], true);
		});
		*/
	},
	changeSite: function(thisBox) {
		globals.site = thisBox.value;
		$.sessionStorage.setItem('site', globals.site);
		if(thisBox.value!='0') {
			globals.changeSite = true;
			$.sessionStorage.setItem('changeSite', true);
		}
		else {
			globals.changeSite = false;
			$.sessionStorage.setItem('changeSite', false);
		}
		setTimeout(function() {
			document.location.reload();
		}, 100);
	},
	refreshSmoothScroll: function() {
		// Smooth Scroll to div on anchors click...
		$('a[href*="#"]').not('a.noscroll').on('click', function (event) {
			event.preventDefault();
			let offset = 0;
			const target = this.hash;
			if($(this).data('offset') != undefined) offset = $(this).data('offset'); // if set data-offset="pixels"
			if($(target).length) {
				$('html, body').stop().animate({
					'scrollTop': $(target).offset().top - offset
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			}
			else { // Scrolls to top...
				$('html, body').stop().animate({
					'scrollTop': 0
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			}
		});
	},
	smoothScrollTo: function(target, offset) {
		// Smooth Scroll to div...
		if($(target).length) {
			$('html, body').stop().animate({
				'scrollTop': $(target).offset().top - offset
			}, 1600, 'swing', function() {
				// window.location.hash = target;
			});
		}
	},
	number_format: function(number, decimals, dec_point, thousands_sep) {
		// *     example: App.number_format(1234.56, 2, ',', ' ');
		// *     return: '1 234,56'
		number = (number + "").replace(",", "").replace(" ", "");
		var n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
			dec = typeof dec_point === "undefined" ? "." : dec_point,
			s = "",
			toFixedFix = function(n, prec) {
				var k = Math.pow(10, prec);
				return "" + Math.round(n * k) / k;
			};
		// Fix for IE parseFloat(0.55).toFixed(0) = 0;
		s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || "").length < prec) {
			s[1] = s[1] || "";
			s[1] += new Array(prec - s[1].length + 1).join("0");
		}
		return s.join(dec);
	},


	setGlobalPage: function(dashType = 'global') {
		if(dashType!='individual') {
		App.getGlobalAnnualPrice();
		App.getGlobalMonthlyPrice();
		App.getGlobalAnnualSales();
		App.getGlobalMonthlySales();
		}
		console.log('here');
		App.fetchSellers();
		// this.getDashIntersDataTable('home');
		// this.getDashActionsDataTable('home');
		// this.getDashBadges('home');
		// this.getGraphicalBarStats('home');
		//this.dashActions();
		if(dashType=='global') {
			this.getGraphicalBasePrice(globals.currentGraph, 'global');
			this.getGraphicalEvolutionSales(globals.currentGraph, 'global');
			this.getSalesBySeller(globals.currentGraph, 'global');
		}
		const aWeekAgo = new Date(new Date().setDate(new Date().getDate()-7));
		const litepickerRangePlugin = document.getElementById('litepickerRangePlugin');
		if (litepickerRangePlugin) {
			new Litepicker({
				element: litepickerRangePlugin,
				startDate: aWeekAgo,
				endDate: new Date(),
				singleMode: false,
				numberOfMonths: 2,
				numberOfColumns: 2,
				format: 'DD/MM/YYYY',
				delimiter: ' > ',
				lang: 'fr-FR',
				tooltipText: {
					one: 'jour',
					other: 'jours'
				},
				tooltipNumber: (totalDays) => {
					return totalDays;
				},
				plugins: ['ranges'],
				ranges: {
					customRanges: {
						'Aujourd\'hui': [new Date(), new Date()],
						'Hier': [new Date(new Date().setDate(new Date().getDate()-1)), new Date(new Date().setDate(new Date().getDate()-1))],
						'Derniers 7 Jours': [aWeekAgo, new Date()],
						'Derniers 30 jours': [new Date(new Date().setDate(new Date().getDate()-29)), new Date()],
						'Ce Mois': [new Date(new Date().setDate(1)), new Date()],
						'Dernier Mois': [new Date(new Date().getFullYear(), new Date().getMonth()-1, 1), new Date(new Date().getFullYear(), new Date().getMonth(), 0)],
						'Cette Année': [new Date(new Date().getFullYear(), 0, 1), new Date()],
						'Année 365': [new Date(new Date().setDate(new Date().getDate()-364)), new Date()],
					},
					// customRangesLabels:["Aujourd'hui","Hier","Derniers 7 Jours","Derniers 30 jours","Ce Mois","Dernier Mois","Cette Année"],
				},
				setup: (picker) => {
					picker.on('selected', (dateStart, dateEnd) => {
						App.refreshDashBoards('global', litepickerRangePlugin);
					});
				},
			});
		}
	},

	setDashPage: function(dashType = 'global') {
		if(dashType=='individual') {
			App.getMyMonthlyPrice();
			App.getMyAnnualPrice();
			App.getMyMonthlySales();
			App.getMyAnnualSales();
		}
		if(dashType=='individual') {
			this.getGraphicalMySales(globals.currentGraph, 'global');
			this.getGraphicalMyPrice(globals.currentGraph, 'global');
		}
		//if (test == undefined) test = 'line'
		// if(globals.type!='accountant') this.backupSummary(false); // Calling Backup Parser Tools
		//this.getDashSalesDataTable('global');
		// this.getDashActionsDataTable('global');
		// this.getDashBadges('global');
		// this.getDashProgressBars('global');
		// this.getDashActivities('created');
		const aWeekAgo = new Date(new Date().setDate(new Date().getDate()-7));
		const litepickerRangePlugin = document.getElementById('litepickerRangePlugin');
		if (litepickerRangePlugin) {
			new Litepicker({
				element: litepickerRangePlugin,
				startDate: aWeekAgo,
				endDate: new Date(),
				singleMode: false,
				numberOfMonths: 2,
				numberOfColumns: 2,
				format: 'DD/MM/YYYY',
				delimiter: ' > ',
				lang: 'fr-FR',
				tooltipText: {
					one: 'jour',
					other: 'jours'
				},
				tooltipNumber: (totalDays) => {
					return totalDays;
				},
				plugins: ['ranges'],
				ranges: {
					customRanges: {
						'Aujourd\'hui': [new Date(), new Date()],
						'Hier': [new Date(new Date().setDate(new Date().getDate()-1)), new Date(new Date().setDate(new Date().getDate()-1))],
						'Derniers 7 Jours': [aWeekAgo, new Date()],
						'Derniers 30 jours': [new Date(new Date().setDate(new Date().getDate()-29)), new Date()],
						'Ce Mois': [new Date(new Date().setDate(1)), new Date()],
						'Dernier Mois': [new Date(new Date().getFullYear(), new Date().getMonth()-1, 1), new Date(new Date().getFullYear(), new Date().getMonth(), 0)],
						'Cette Année': [new Date(new Date().getFullYear(), 0, 1), new Date()],
						'Année 365': [new Date(new Date().setDate(new Date().getDate()-364)), new Date()],
					},
					// customRangesLabels:["Aujourd'hui","Hier","Derniers 7 Jours","Derniers 30 jours","Ce Mois","Dernier Mois","Cette Année"],
				},
				setup: (picker) => {
					picker.on('selected', (dateStart, dateEnd) => {
						App.refreshDashBoards('global', litepickerRangePlugin);
					});
				},
			});
		}
	},
	refreshDashBoards: function(dashType, thisInput) {
		// this.backupSummary(false); // Calling Backup Parser Tools
		const range = $(thisInput).val();
		$('.dataTable').DataTable().destroy(true); // Make sure previous DataTables are Destroyed => fixedHeader bug fix !
		this.getDashSalesDataTable(dashType, range);
		if(dashType=='global') {
			this.getSalesBySeller(globals.currentGraph, dashType, range);
			this.getGraphicalBasePrice(globals.currentGraph, dashType, range);
			this.getGraphicalEvolutionSales(globals.currentGraph, dashType, range);
			this.getGraphicalMySales(globals.currentGraph, dashType, range);
			this.getGraphicalMyPrice(globals.currentGraph, dashType, range);
		}
	},
	getDashSalesDataTable: function(dashType, myRange) {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, site: globals.site, range: myRange, req: 'dashController', action: 'getDashSalesDataTable', dash: dashType}, function(data){
			$("#dashSalesListCont").empty().append(data.snippet);
		}, "json").done(function(data) {
			if(dashType=='home') App.buildDataTables('#dataTableDashSalesList', 25, true, 0, 'desc', 'Mozart Autos - Liste de mes ventes', [0,1,2,3,4,7], [5,6], '', '', '', [0,1,2,3,4,5,6,7], true);
			else App.buildDataTables('#dataTableDashSalesList', 25, true, 1, 'desc', 'Mozart Autos - Liste des 1000 dernières Ventes réalisées', [0,1,2,3,4,8], [5,6,7], '', '', '', [0,1,2,3,4,5,6,7,8], true);
		}).always(function(){
			// App.getFormsSelectBoxes(['#saleForm', '#saleForm'], 'salesController', ['AgenceGestionSale','AgenceFacturationSale']);
		});
	},

	getSalesBySeller: function(dashStyle, dashType, myRange) {
		if (dashStyle == undefined) dashStyle = 'bar'
		if (myRange == undefined) myRange = globals.currentRange;
		globals.currentRange = myRange;
		globals.currentGraph = dashStyle;
		if(globals.myGraphicalSalesBySeller) globals.myGraphicalSalesBySeller.destroy();
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, site: globals.site, dash: dashType, range: myRange, req: 'dashController', action: 'getSalesBySeller'}, function(data){
			globals.myGraphicalSalesBySeller = new Chart(document.getElementById("graphicalSalesBySeller"), {
				type: dashStyle,
				data: {
					labels: data.salesBySellerLabels,
					datasets: [{
						label: "Ventes",
						lineTension: 0.3,
						backgroundColor: "rgba(105, 0, 199, 0.05)",
						borderColor: "rgba(8, 159, 227, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(8, 159, 227, 1)",
						pointBorderColor: "rgba(8, 159, 227, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(8, 159, 227, 1)",
						pointHoverBorderColor: "rgba(8, 159, 227, 1)",
						pointHoverBackgroundColor: "rgba(8, 159, 227, 1)",
						pointHoverBorderColor: "rgba(8, 159, 227, 1)",
						backgroundColor: "rgba(8, 159, 227, 1)",
						hoverBackgroundColor: "rgba(8, 159, 227, 1)",
						borderColor: "#4e73df",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						fill: 'origin',
						data: data.salesBySellerData
					}, /* {
						label: "Créations",
						lineTension: 0.3,
						backgroundColor: "rgba(255, 193, 71, 0.05)",
						borderColor: "rgba(240, 125, 1, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(240, 125, 1, 1)",
						pointBorderColor: "rgba(240, 125, 1, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(240, 125, 1, 1)",
						pointHoverBorderColor: "rgba(240, 125, 1, 1)",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						fill: 'origin',
						data: data.basePriceDataCreated
					} */]
				},
				options: {
					responsive: true,
					maintainAspectRatio:false,
					legend:{'display':true, position: 'bottom'},
					layout: {
						padding: {
							left: 10,
							right: 25,
							top: 25,
							bottom: 0
						},
						// height: 460,
					},
					scales: {
						x: {
							time: {
								unit: "date"
							},
							gridLines: {
								display: false,
								drawBorder: false
							},
							ticks: {
								maxTicksLimit: 7
							}
						},
						y: {
							ticks: {
								maxTicksLimit: 5,
								padding: 10,
								// Format numbers in the ticks
								callback: function(value, index, values) {
									return App.number_format(value, 2, ',', ' ');
								}
							},
							gridLines: {
								color: "rgb(234, 236, 244)",
								zeroLineColor: "rgb(234, 236, 244)",
								drawBorder: false,
								borderDash: [2],
								zeroLineBorderDash: [2]
							}
						}
					},
					tooltips: {
						backgroundColor: "rgb(255,255,255)",
						bodyFontColor: "#858796",
						titleMarginBottom: 10,
						titleFontColor: "#6e707e",
						titleFontSize: 14,
						borderColor: "#dddfeb",
						borderWidth: 1,
						xPadding: 15,
						yPadding: 15,
						displayColors: false,
						intersect: false,
						mode: "index",
						caretPadding: 10,
						callbacks: {
							label: function(tooltipItem, chart) {
								var datasetLabel =
									chart.datasets[tooltipItem.datasetIndex].label || "";
								return datasetLabel + ": " + App.number_format(tooltipItem.yLabel, 2, ',', ' ');
							}
						}
					}
				}
			});
		}, "json");
	},

	getGraphicalBasePrice: function(dashStyle, dashType, myRange) {
		if (dashStyle == undefined) dashStyle = 'bar'
		if (myRange == undefined) myRange = globals.currentRange
		globals.currentRange = myRange;
		globals.currentGraph = dashStyle;
		if(globals.myGraphicalBasePrice) globals.myGraphicalBasePrice.destroy();
		console.log(dashStyle);
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, site: globals.site,dash: dashType, range: myRange, req: 'dashController', action: 'getGraphicalBasePrice'}, function(data){
			globals.myGraphicalBasePrice = new Chart(document.getElementById("graphicalBasePrice"), {
				type: dashStyle,
				data: {
					labels: data.basePriceLabels,
					datasets: [{
						label: "Recette",
						lineTension: 0.3,
						backgroundColor: "rgba(105, 0, 199, 0.05)",
						borderColor: "rgba(8, 159, 227, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(8, 159, 227, 1)",
						pointBorderColor: "rgba(8, 159, 227, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(8, 159, 227, 1)",
						pointHoverBorderColor: "rgba(8, 159, 227, 1)",
						pointHoverBackgroundColor: "rgba(8, 159, 227, 1)",
						pointHoverBorderColor: "rgba(8, 159, 227, 1)",
						backgroundColor: "rgba(8, 159, 227, 1)",
						hoverBackgroundColor: "rgba(8, 159, 227, 1)",
						borderColor: "#4e73df",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						fill: 'origin',
						data: data.basePriceDataSales
					}, /* {
						label: "Créations",
						lineTension: 0.3,
						backgroundColor: "rgba(255, 193, 71, 0.05)",
						borderColor: "rgba(240, 125, 1, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(240, 125, 1, 1)",
						pointBorderColor: "rgba(240, 125, 1, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(240, 125, 1, 1)",
						pointHoverBorderColor: "rgba(240, 125, 1, 1)",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						fill: 'origin',
						data: data.basePriceDataCreated
					} */]
				},
				options: {
					responsive: true,
					maintainAspectRatio:false,
					legend:{'display':true, position: 'bottom'},
					layout: {
						padding: {
							left: 10,
							right: 25,
							top: 25,
							bottom: 0
						},
						// height: 460,
					},
					scales: {
						x: {
							time: {
								unit: "date"
							},
							gridLines: {
								display: false,
								drawBorder: false
							},
							ticks: {
								maxTicksLimit: 7
							}
						},
						y: {
							ticks: {
								maxTicksLimit: 5,
								padding: 10,
								// Format numbers in the ticks
								callback: function(value, index, values) {
									return App.number_format(value, 2, ',', ' ');
								}
							},
							gridLines: {
								color: "rgb(234, 236, 244)",
								zeroLineColor: "rgb(234, 236, 244)",
								drawBorder: false,
								borderDash: [2],
								zeroLineBorderDash: [2]
							}
						}
					},
					tooltips: {
						backgroundColor: "rgb(255,255,255)",
						bodyFontColor: "#858796",
						titleMarginBottom: 10,
						titleFontColor: "#6e707e",
						titleFontSize: 14,
						borderColor: "#dddfeb",
						borderWidth: 1,
						xPadding: 15,
						yPadding: 15,
						displayColors: false,
						intersect: false,
						mode: "index",
						caretPadding: 10,
						callbacks: {
							label: function(tooltipItem, chart) {
								var datasetLabel =
									chart.datasets[tooltipItem.datasetIndex].label || "";
								return datasetLabel + ": " + App.number_format(tooltipItem.yLabel, 2, ',', ' ');
							}
						}
					}
				}
			});
		}, "json");
	},

	getGraphicalEvolutionSales: function(dashStyle, dashType, myRange) {
		if (dashStyle == undefined) dashStyle = 'bar'
		if (myRange == undefined) myRange = globals.currentRange
		globals.currentRange = myRange;
		globals.currentGraph = dashStyle;
		if(globals.myGraphicalEvolutionSales) globals.myGraphicalEvolutionSales.destroy();
		console.log(dashStyle);
	/* 	 if(globals.myPieChart) globals.myPieChart.destroy();
		 if(globals.myBarChart) globals.myBarChart.destroy(); */
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, site: globals.site,dash: dashType, range: myRange, req: 'dashController', action: 'getGraphicalEvolutionSales'}, function(data){
			globals.myGraphicalEvolutionSales = new Chart(document.getElementById("graphicalEvolutionSales"), {
				type: dashStyle,
				data: {
					labels: data.evolutionSalesLabels,
					datasets: [{
						label: "Ventes",
						lineTension: 0.3,
						backgroundColor: "rgba(105, 0, 199, 0.05)",
						borderColor: "rgba(8, 159, 227, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(8, 159, 227, 1)",
						pointBorderColor: "rgba(8, 159, 227, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(8, 159, 227, 1)",
						pointHoverBorderColor: "rgba(8, 159, 227, 1)",
						pointHoverBackgroundColor: "rgba(8, 159, 227, 1)",
						pointHoverBorderColor: "rgba(8, 159, 227, 1)",
						backgroundColor: "rgba(8, 159, 227, 1)",
						hoverBackgroundColor: "rgba(8, 159, 227, 1)",
						borderColor: "#4e73df",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						fill: 'origin',
						data: data.evolutionSalesDataSales
					}, /* {
						label: "Créations",
						lineTension: 0.3,
						backgroundColor: "rgba(255, 193, 71, 0.05)",
						borderColor: "rgba(240, 125, 1, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(240, 125, 1, 1)",
						pointBorderColor: "rgba(240, 125, 1, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(240, 125, 1, 1)",
						pointHoverBorderColor: "rgba(240, 125, 1, 1)",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						fill: 'origin',
						data: data.evolutionSalesDataCreated
					} */]
				},
				options: {
					responsive: true,
					maintainAspectRatio:false,
					legend:{'display':true, position: 'bottom'},
					layout: {
						padding: {
							left: 10,
							right: 25,
							top: 25,
							bottom: 0
						},
						// height: 460,
					},
					scales: {
						x: {
							time: {
								unit: "date"
							},
							gridLines: {
								display: false,
								drawBorder: false
							},
							ticks: {
								maxTicksLimit: 7
							}
						},
						y: {
							ticks: {
								maxTicksLimit: 5,
								padding: 10,
								// Format numbers in the ticks
								callback: function(value, index, values) {
									return App.number_format(value, 2, ',', ' ');
								}
							},
							gridLines: {
								color: "rgb(234, 236, 244)",
								zeroLineColor: "rgb(234, 236, 244)",
								drawBorder: false,
								borderDash: [2],
								zeroLineBorderDash: [2]
							}
						}
					},
					tooltips: {
						backgroundColor: "rgb(255,255,255)",
						bodyFontColor: "#858796",
						titleMarginBottom: 10,
						titleFontColor: "#6e707e",
						titleFontSize: 14,
						borderColor: "#dddfeb",
						borderWidth: 1,
						xPadding: 15,
						yPadding: 15,
						displayColors: false,
						intersect: false,
						mode: "index",
						caretPadding: 10,
						callbacks: {
							label: function(tooltipItem, chart) {
								var datasetLabel =
									chart.datasets[tooltipItem.datasetIndex].label || "";
								return datasetLabel + ": " + App.number_format(tooltipItem.yLabel, 2, ',', ' ');
							}
						}
					}
				}
			});
		}, "json");
	},

	getGraphicalMySales: function(dashStyle, dashType, myRange) {
		if (dashStyle == undefined) dashStyle = 'bar'
		if (myRange == undefined) myRange = globals.currentRange
		globals.currentRange = myRange;
		globals.currentGraph = dashStyle;
		if(globals.myGraphicalMySales) globals.myGraphicalMySales.destroy();
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, site: globals.site,dash: dashType, range: myRange, req: 'dashController', action: 'getGraphicalMySales'}, function(data){
			globals.myGraphicalMySales = new Chart(document.getElementById("graphicalMySales"), {
				type: dashStyle,
				data: {
					labels: data.mySalesLabels,
					datasets: [{
						label: "Ventes",
						lineTension: 0.3,
						backgroundColor: "rgba(8, 159, 227, 0.5)",
						borderColor: "rgba(8, 159, 227, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(8, 159, 227, 1)",
						pointBorderColor: "rgba(8, 159, 227, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(8, 159, 227, 1)",
						pointHoverBorderColor: "rgba(8, 159, 227, 1)",
						pointHoverBackgroundColor: "rgba(8, 159, 227, 1)",
						pointHoverBorderColor: "rgba(8, 159, 227, 1)",
						hoverBackgroundColor: "rgba(8, 159, 227, 1)",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						fill: 'origin',
						data: data.mySalesData
					}, /* {
						label: "Créations",
						lineTension: 0.3,
						backgroundColor: "rgba(255, 193, 71, 0.05)",
						borderColor: "rgba(240, 125, 1, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(240, 125, 1, 1)",
						pointBorderColor: "rgba(240, 125, 1, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(240, 125, 1, 1)",
						pointHoverBorderColor: "rgba(240, 125, 1, 1)",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						fill: 'origin',
						data: data.evolutionSalesDataCreated
					} */]
				},
				options: {
					responsive: true,
					maintainAspectRatio:false,
					legend:{'display':true, position: 'bottom'},
					layout: {
						padding: {
							left: 10,
							right: 25,
							top: 25,
							bottom: 0
						},
						// height: 460,
					},
					scales: {
						x: {
							time: {
								unit: "date"
							},
							gridLines: {
								display: false,
								drawBorder: false
							},
							ticks: {
								maxTicksLimit: 7
							}
						},
						y: {
							ticks: {
								maxTicksLimit: 5,
								padding: 10,
								// Format numbers in the ticks
								callback: function(value, index, values) {
									return App.number_format(value, 2, ',', ' ');
								}
							},
							gridLines: {
								color: "rgb(234, 236, 244)",
								zeroLineColor: "rgb(234, 236, 244)",
								drawBorder: false,
								borderDash: [2],
								zeroLineBorderDash: [2]
							}
						}
					},
					tooltips: {
						backgroundColor: "rgb(255,255,255)",
						bodyFontColor: "#858796",
						titleMarginBottom: 10,
						titleFontColor: "#6e707e",
						titleFontSize: 14,
						borderColor: "#dddfeb",
						borderWidth: 1,
						xPadding: 15,
						yPadding: 15,
						displayColors: false,
						intersect: false,
						mode: "index",
						caretPadding: 10,
						callbacks: {
							label: function(tooltipItem, chart) {
								var datasetLabel =
									chart.datasets[tooltipItem.datasetIndex].label || "";
								return datasetLabel + ": " + App.number_format(tooltipItem.yLabel, 2, ',', ' ');
							}
						}
					}
				}
			});
		}, "json");
	},

	getGraphicalMyPrice: function(dashStyle, dashType, myRange) {
		if (dashStyle == undefined) dashStyle = 'bar'
		if (myRange == undefined) myRange = globals.currentRange
		globals.currentRange = myRange;
		globals.currentGraph = dashStyle;
		if(globals.myGraphicalMyPrice) globals.myGraphicalMyPrice.destroy();
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, site: globals.site,dash: dashType, range: myRange, req: 'dashController', action: 'getGraphicalMyPrice'}, function(data){
			globals.myGraphicalMyPrice = new Chart(document.getElementById("graphicalMyPrice"), {
				type: dashStyle,
				data: {
					labels: data.myPriceLabels,
					datasets: [{
						label: "Recette",
						lineTension: 0.3,
						backgroundColor: "rgba(105, 0, 199, 0.05)",
						borderColor: "rgba(8, 159, 227, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(8, 159, 227, 1)",
						pointBorderColor: "rgba(8, 159, 227, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(8, 159, 227, 1)",
						pointHoverBorderColor: "rgba(8, 159, 227, 1)",
						backgroundColor: "rgba(8, 159, 227, 1)",
						hoverBackgroundColor: "rgba(8, 159, 227, 1)",
						maxBarThickness: 25,
						pointHitRadius: 10,
						pointBorderWidth: 2,
						fill: 'origin',
						data: data.myPriceData
					}, /* {
						label: "Créations",
						lineTension: 0.3,
						backgroundColor: "rgba(255, 193, 71, 0.05)",
						borderColor: "rgba(240, 125, 1, 1)",
						pointRadius: 3,
						pointBackgroundColor: "rgba(240, 125, 1, 1)",
						pointBorderColor: "rgba(240, 125, 1, 1)",
						pointHoverRadius: 3,
						pointHoverBackgroundColor: "rgba(240, 125, 1, 1)",
						pointHoverBorderColor: "rgba(240, 125, 1, 1)",
						pointHitRadius: 10,
						pointBorderWidth: 2,
						fill: 'origin',
						data: data.evolutionSalesDataCreated
					} */]
				},
				options: {
					responsive: true,
					maintainAspectRatio:false,
					legend:{'display':true, position: 'bottom'},
					layout: {
						padding: {
							left: 10,
							right: 25,
							top: 25,
							bottom: 0
						},
						// height: 460,
					},
					scales: {
						x: {
							time: {
								unit: "Month"
							},
							gridLines: {
								display: false,
								drawBorder: false
							},
							ticks: {
								maxTicksLimit: 7
							}
						},
						y: {
							ticks: {
								maxTicksLimit: 5,
								padding: 10,
								// Format numbers in the ticks
								callback: function(value, index, values) {
									return App.number_format(value, 2, ',', ' ');
								}
							},
							gridLines: {
								color: "rgb(234, 236, 244)",
								zeroLineColor: "rgb(234, 236, 244)",
								drawBorder: false,
								borderDash: [2],
								zeroLineBorderDash: [2]
							}
						}
					},
					tooltips: {
						backgroundColor: "rgb(255,255,255)",
						bodyFontColor: "#858796",
						titleMarginBottom: 10,
						titleFontColor: "#6e707e",
						titleFontSize: 14,
						borderColor: "#dddfeb",
						borderWidth: 1,
						xPadding: 15,
						yPadding: 15,
						displayColors: false,
						intersect: false,
						mode: "index",
						caretPadding: 10,
						callbacks: {
							label: function(tooltipItem, chart) {
								var datasetLabel =
									chart.datasets[tooltipItem.datasetIndex].label || "";
								return datasetLabel + ": " + App.number_format(tooltipItem.yLabel, 2, ',', ' ');
							}
						}
					}
				}
			});
		}, "json");
	},


		/* const ctx1 = document.getElementById("myTestChart");
		globals.myBarChart = new Chart(ctx1, {
			type: "bar",
dashActions: function() {

		const datatablesSimple = document.getElementById('datatablesSimple');
		if (datatablesSimple) {
			App.buildDataTables('#datatablesSimple', 25, true, 4, 'desc', 'DEMO', [0,1,2,3,4,5,6], []);
		}
		if(globals.myLineChart) globals.myLineChart.destroy();
		if(globals.myBarChart) globals.myBarChart.destroy();
	},			data: {
				labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin"],
				datasets: [{
					label: "Revenue",
					backgroundColor: "rgba(0, 97, 242, 1)",
					hoverBackgroundColor: "rgba(0, 97, 242, 0.9)",
					borderColor: "#4e73df",
					data: [4215, 5312, 6251, 7841, 9821, 14984],
					maxBarThickness: 25
				}]
			},
			options: {
				maintainAspectRatio: false,
				layout: {
					padding: {
						left: 10,
						right: 25,
						top: 25,
						bottom: 0
					}
				},
				scales: {
					xAxes: {
						time: {
							unit: "month"
						},
						gridLines: {
							display: false,
							drawBorder: false
						},
						ticks: {
							maxTicksLimit: 6
						}
					},
					yAxes: {
						ticks: {
							min: 0,
							max: 15000,
							maxTicksLimit: 5,
							padding: 10,
							// Include a dollar sign in the ticks
							callback: function(value, index, values) {
								return "$" + App.number_format(value);
							}
						},
						gridLines: {
							color: "rgb(234, 236, 244)",
							zeroLineColor: "rgb(234, 236, 244)",
							drawBorder: false,
							borderDash: [2],
							zeroLineBorderDash: [2]
						}
					}
				},
				legend: {
					display: false
				},
				tooltips: {
					titleMarginBottom: 10,
					titleFontColor: "#6e707e",
					titleFontSize: 14,
					backgroundColor: "rgb(255,255,255)",
					bodyFontColor: "#858796",
					borderColor: "#dddfeb",
					borderWidth: 1,
					xPadding: 15,
					yPadding: 15,
					displayColors: false,
					caretPadding: 10,
					callbacks: {
						label: function(tooltipItem, chart) {
							var datasetLabel =
								chart.datasets[tooltipItem.datasetIndex].label || "";
							return datasetLabel + ": $" + App.number_format(tooltipItem.yLabel);
						}
					}
				}
			}
		});
		const ctx2 = document.getElementById("myUsersChart");
		globals.myBarChart = new Chart(ctx2, {
			type: "bar",
			data: {
				labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin"],
				datasets: [{
					label: "Revenue",
					backgroundColor: "rgba(0, 97, 242, 1)",
					hoverBackgroundColor: "rgba(0, 97, 242, 0.9)",
					borderColor: "#4e73df",
					data: [4215, 5312, 6251, 7841, 9821, 14984],
					maxBarThickness: 25
				}]
			},
			options: {
				maintainAspectRatio: false,
				layout: {
					padding: {
						left: 10,
						right: 25,
						top: 25,
						bottom: 0
					}
				},
				scales: {
					xAxes: {
						time: {
							unit: "month"
						},
						gridLines: {
							display: false,
							drawBorder: false
						},
						ticks: {
							maxTicksLimit: 6
						}
					},
					yAxes: {
						ticks: {
							min: 0,
							max: 15000,
							maxTicksLimit: 5,
							padding: 10,
							// Include a dollar sign in the ticks
							callback: function(value, index, values) {
								return "$" + App.number_format(value);
							}
						},
						gridLines: {
							color: "rgb(234, 236, 244)",
							zeroLineColor: "rgb(234, 236, 244)",
							drawBorder: false,
							borderDash: [2],
							zeroLineBorderDash: [2]
						}
					}
				},
				legend: {
					display: false
				},
				tooltips: {
					titleMarginBottom: 10,
					titleFontColor: "#6e707e",
					titleFontSize: 14,
					backgroundColor: "rgb(255,255,255)",
					bodyFontColor: "#858796",
					borderColor: "#dddfeb",
					borderWidth: 1,
					xPadding: 15,
					yPadding: 15,
					displayColors: false,
					caretPadding: 10,
					callbacks: {
						label: function(tooltipItem, chart) {
							var datasetLabel =
								chart.datasets[tooltipItem.datasetIndex].label || "";
							return datasetLabel + ": $" + App.number_format(tooltipItem.yLabel);
						}
					}
				}
			}
		});
		if (chartType.value === 'line'){
			const ctx3 = document.getElementById("myTestChart");
			globals.myLineChart = new Chart(ctx3, {
				type: "line",
				data: {
					labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin"],
					datasets: [{
						label: "Revenue",
						backgroundColor: "rgba(0, 97, 242, 1)",
						hoverBackgroundColor: "rgba(0, 97, 242, 0.9)",
						borderColor: "#4e73df",
						data: [4215, 5312, 6251, 7841, 9821, 14984],
						maxBarThickness: 25
					}]
				},
				options: {
					maintainAspectRatio: false,
					layout: {
						padding: {
							left: 10,
							right: 25,
							top: 25,
							bottom: 0
						}
					},
					scales: {
						xAxes: {
							time: {
								unit: "month"
							},
							gridLines: {
								display: false,
								drawBorder: false
							},
							ticks: {
								maxTicksLimit: 6
							}
						},
						yAxes: {
							ticks: {
								min: 0,
								max: 15000,
								maxTicksLimit: 5,
								padding: 10,
								// Include a dollar sign in the ticks
								callback: function(value, index, values) {
									return "$" + App.number_format(value);
								}
							},
							gridLines: {
								color: "rgb(234, 236, 244)",
								zeroLineColor: "rgb(234, 236, 244)",
								drawBorder: false,
								borderDash: [2],
								zeroLineBorderDash: [2]
							}
						}
					},
					legend: {
						display: false
					},
					tooltips: {
						titleMarginBottom: 10,
						titleFontColor: "#6e707e",
						titleFontSize: 14,
						backgroundColor: "rgb(255,255,255)",
						bodyFontColor: "#858796",
						borderColor: "#dddfeb",
						borderWidth: 1,
						xPadding: 15,
						yPadding: 15,
						displayColors: false,
						caretPadding: 10,
						callbacks: {
							label: function(tooltipItem, chart) {
								var datasetLabel =
									chart.datasets[tooltipItem.datasetIndex].label || "";
								return datasetLabel + ": $" + App.number_format(tooltipItem.yLabel);
							}

						}

					}
				}

			});
		}
		// Area Chart Example
		const ctx23 = document.getElementById("saleChart");
		globals.myLineChart = new Chart(ctx2, {
			type: "line",
			data: {
				labels: [
					"Jan",
					"Feb",
					"Mar",
					"Apr",
					"May",
					"Jun",
					"Jul",
					"Aug",
					"Sep",
					"Oct",
					"Nov",
					"Dec"
				],
				datasets: [{
					label: "Earnings",
					lineTension: 0.3,
					backgroundColor: "rgba(0, 97, 242, 0.05)",
					borderColor: "rgba(0, 97, 242, 1)",
					pointRadius: 3,
					pointBackgroundColor: "rgba(0, 97, 242, 1)",
					pointBorderColor: "rgba(0, 97, 242, 1)",
					pointHoverRadius: 3,
					pointHoverBackgroundColor: "rgba(0, 97, 242, 1)",
					pointHoverBorderColor: "rgba(0, 97, 242, 1)",
					pointHitRadius: 10,
					pointBorderWidth: 2,
					data: [
						0,
						10000,
						5000,
						15000,
						10000,
						20000,
						15000,
						25000,
						20000,
						30000,
						25000,
						40000
					]
				}]
			},
			options: {
				maintainAspectRatio: false,
				layout: {
					padding: {
						left: 10,
						right: 25,
						top: 25,
						bottom: 0
					}
				},
				scales: {
					xAxes: {
						time: {
							unit: "date"
						},
						gridLines: {
							display: false,
							drawBorder: false
						},
						ticks: {
							maxTicksLimit: 7
						}
					},
					yAxes: {
						ticks: {
							maxTicksLimit: 5,
							padding: 10,
							// Include a dollar sign in the ticks
							callback: function(value, index, values) {
								return "$" + App.number_format(value);
							}
						},
						gridLines: {
							color: "rgb(234, 236, 244)",
							zeroLineColor: "rgb(234, 236, 244)",
							drawBorder: false,
							borderDash: [2],
							zeroLineBorderDash: [2]
						}
					}
				},
				legend: {
					display: false
				},
				tooltips: {
					backgroundColor: "rgb(255,255,255)",
					bodyFontColor: "#858796",
					titleMarginBottom: 10,
					titleFontColor: "#6e707e",
					titleFontSize: 14,
					borderColor: "#dddfeb",
					borderWidth: 1,
					xPadding: 15,
					yPadding: 15,
					displayColors: false,
					intersect: false,
					mode: "index",
					caretPadding: 10,
					callbacks: {
						label: function(tooltipItem, chart) {
							var datasetLabel =
								chart.datasets[tooltipItem.datasetIndex].label || "";
							return datasetLabel + ": $" + App.number_format(tooltipItem.yLabel);
						}
					}
				}
			}
		});
	},
	*/
	/**
	 *
	 * @param {string} myDiv 
	 * @param {int} pageLength 
	 * @param {Boolean} colReorder 
	 * @param {int} orderCol 
	 * @param {string} orderWay asc | desc 
	 * @param {string} expTitle 
	 * @param {array} filterColumsText 
	 * @param {array} filterColumsSelect 
	 * @param {string} myFilterField Centre 
	 * @param {string} myFilterCondition = 
	 * @param {string} myFilter myfilter
	 * @param {array} pdfExtraExportOptions 
	 * @param {boolean} fixedHeader 
	 */
	buildDataTables: function(myDiv, pageLength, colReorder, orderCol, orderWay, expTitle, filterColumsText, filterColumsSelect, myFilterField, myFilterCondition, myFilter, pdfExtraExportOptions, fixedHeader) {
		// Like : App.buildDataTables('#dataTable', 25, true, 4, 'desc', 'Titre', [0,1,2,3,4,5,6], '');
		// Or : App.buildDataTables('#dataTable', 25, true, 4, 'desc', 'Titre', [0,1,2,3,4,5,6], [7,8], 'Centre', '=', myFilter, [0,1,2,3,4,5,6], true);
		fixedHeader = (typeof fixedHeader !== 'undefined') ? fixedHeader : true;
		let fixedFooter = false;
		let headerOffset = 60;
		if(globals.isMobile) fixedHeader = false;
		const dom = (globals.type=='user') ? '<"col-6 d-flex align-items-center"l><"col-6 d-flex align-items-center justify-content-end"f>rt<"col-12 col-md-6"i><"col-12 col-md-6"p>' : '<"col-12 col-md-4 d-flex align-items-center"l><"col-12 dtOptionHide col-md-4 d-flex align-items-center justify-content-end justify-content-md-center"f><"col-12 col-md-4 d-flex align-items-center justify-content-end"B>rt<"col-12 col-md-6"i><"col-12 col-md-6"p>';
		// Add row class to dataTable wrapper => Usefull to correcty display buttons using the dom option => Done in css directly on .dataTables_wrapper
		// $(myDiv+'_wrapper').addClass('row');
		// Add filter row in header...
		// $(myDiv+' thead tr').clone(true).addClass('filters').appendTo(myDiv+' thead');
		const pdfExtra = (typeof pdfExtraExportOptions !== 'undefined') ? pdfExtraExportOptions : '';
		const baseFiltering = (typeof myFilter !== 'undefined') ? myFilter : '';
		let myTable;
		if (baseFiltering.length>0) {
			let tabFilter = new Array(myFilter);
			myTable = $(myDiv).DataTable({
				destroy: true,
				dom: dom,
				language: globals.dataTablesFrench,
				// language: {
				// 	// url: 'https://cdn.datatables.net/plug-ins/1.11.3/i18n/fr_fr.json'
				// 	// url: globals.serverAddress+'datatables_fr_FR.php'
				// },
				"pageLength": pageLength,
				colReorder: colReorder,
				fixedHeader: {header: fixedHeader, footer: fixedFooter, headerOffset: headerOffset},
				"order": [], // disable default sorting order wi'll apply ordering conditionaly below
				buttons: [
					{text:'<li class="fa fa-2x fa-copy"></li>', title: expTitle, extend:'copy'},
					{text:'<li class="fa fa-2x fa-file-excel"></li>', title: expTitle, extend:'excel'},
					{text:'<li class="fa fa-2x fa-file-pdf"></li>', title: expTitle, exportOptions: {stripNewlines: false, columns: pdfExtra}, orientation: 'landscape', pageSize: 'LEGAL', extend:'pdf'},
					// {text:'<li class="fa fa-2x fa-file-pdf"></li>', title: 'Mozart Autos - Liste des Bordereaux', orientation: 'landscape', pageSize: 'LEGAL', exportOptions: {columns:[0,1,2,3,5,6,7]}, extend:'pdf'},
					{text:'<li class="fa fa-2x fa-print"></li>', title: expTitle, exportOptions: {stripHtml: false, stripNewlines: false}, extend:'print'}
				],
				searchBuilder: {
					preDefined: {
						criteria: [
							{
								data: myFilterField,
								condition: myFilterCondition,
								value: tabFilter
							}
						]
					}
				},
				initComplete: function () {
					$(myDiv+' thead tr').clone(false).addClass('filters').appendTo(myDiv+' thead');
					$(myDiv+' tr.filters .filter-false').empty().removeClass('sorting');
					if(filterColumsText.length>0) {
						this.api().columns(filterColumsText).every( function () {
							let column = this;
							var cell = $(myDiv+' tr.filters th').eq($(column.header()).index()).empty().removeClass(['sorting', 'sorting_asc', 'sorting_desc']);
							let input = $('<input class="form-control form-control-sm table-filter" type="text" placeholder="Rechercher" />')
							.appendTo( $(cell) )
							// .appendTo( $(column.header()) )
							.off('keyup change')
							.on('keyup change', function (e) {
								e.stopPropagation();
								let val = $.fn.dataTable.util.escapeRegex(
									$(this).val()
								);
								let regexr = '({search})';
								column.search(val != '' ? regexr.replace('{search}', '(((' + val + ')))') : '', val != '', val == '').draw();
							});
						});
					}
					if(filterColumsSelect.length>0) {
						this.api().columns(filterColumsSelect).every( function () {
							let column = this;
							let filterDataArray = [];
							var cell = $(myDiv+' tr.filters th').eq($(column.header()).index()).empty().removeClass(['sorting', 'sorting_asc', 'sorting_desc']);
							let select = $('<select class="form-select form-select-sm table-filter"><option value=""></option></select>')
							.appendTo( $(cell) )
							// .appendTo( $(column.header()) )
								.on( 'change', function () {
									let val = $.fn.dataTable.util.escapeRegex(
										$(this).val()
									);
									column
										.search( val ? '^'+val+'$' : '', true, false )
										.draw();
								});
							column.data().unique().sort().each( function ( d, j ) {
								let filterData = d;
								if(d.indexOf('</') !== -1) { // In case there is html tags we strip those...
									filterData = ($(d).length) ? $(d).text() : d;
									// console.warn(d+' - '+$(d));
								}
								// select.append( '<option value="'+filterData+'">'+filterData+'</option>' );
								if(filterData!='') filterDataArray.push(filterData);
							});
							// In case there was html tags we need to sort the data again
							filterDataArray.sort();
							filterDataArray.forEach(element => {
								select.append( '<option value="'+element+'">'+element+'</option>' );
							});
						});
					}
				}
			});
		}
		else {
			myTable = $(myDiv).DataTable({
				destroy: true,
				dom: dom,
				language: globals.dataTablesFrench,
				// language: {
				// 	// url: 'https://cdn.datatables.net/plug-ins/1.11.3/i18n/fr_fr.json'
				// 	url: globals.serverAddress+'datatables_fr_FR.php'
				// },
				"pageLength": pageLength,
				colReorder: colReorder,
				fixedHeader: {header: fixedHeader, footer: fixedFooter, headerOffset: headerOffset},
				"order": [], // disable default sorting order wi'll apply ordering conditionaly below
				buttons: [
					{text:'<li class="fa fa-2x fa-copy"></li>', title: expTitle, extend:'copy'},
					{text:'<li class="fa fa-2x fa-file-excel"></li>', title: expTitle, extend:'excel'},
					{text:'<li class="fa fa-2x fa-file-pdf"></li>', title: expTitle, exportOptions: {stripNewlines: false, columns: pdfExtra}, orientation: 'landscape', pageSize: 'LEGAL', extend:'pdf'},
					{text:'<li class="fa fa-2x fa-print"></li>', title: expTitle, exportOptions: {stripHtml: false, stripNewlines: false}, extend:'print'}
				],
				initComplete: function () {
					$(myDiv+' thead tr').clone(false).addClass('filters').appendTo(myDiv+' thead');
					$(myDiv+' tr.filters .filter-false').empty().removeClass('sorting');
					if(filterColumsText.length>0) {
						this.api().columns(filterColumsText).every( function () {
							let column = this;
							var cell = $(myDiv+' tr.filters th').eq($(column.header()).index()).empty().removeClass(['sorting', 'sorting_asc', 'sorting_desc']);
							let input = $('<input class="form-control form-control-sm table-filter" type="text" placeholder="Rechercher" />')
							.appendTo( $(cell) )
							// .appendTo( $(column.header()) )
							.off('keyup change')
							.on('keyup change', function (e) {
								e.stopPropagation();
								let val = $.fn.dataTable.util.escapeRegex(
									$(this).val()
								);
								let regexr = '({search})';
								column.search(val != '' ? regexr.replace('{search}', '(((' + val + ')))') : '', val != '', val == '').draw();
							});
						});
					}
					if(filterColumsSelect.length>0) {
						this.api().columns(filterColumsSelect).every( function () {
							let column = this;
							let filterDataArray = [];
							var cell = $(myDiv+' tr.filters th').eq($(column.header()).index()).empty().removeClass(['sorting', 'sorting_asc', 'sorting_desc']);
							let select = $('<select class="form-select form-select-sm table-filter"><option value=""></option></select>')
							.appendTo( $(cell) )
							// .appendTo( $(column.header()) )
								.on( 'change', function () {
									let val = $.fn.dataTable.util.escapeRegex(
										$(this).val()
									);
									column
										.search( val ? '^'+val+'$' : '', true, false )
										.draw();
								});
							column.data().unique().sort().each( function ( d, j ) {
								let filterData = d;
								if(d.indexOf('</') !== -1) { // In case there is html tags we strip those...
									filterData = ($(d).length) ? $(d).text() : d;
									// console.warn(d+' - '+$(d));
								}
								// select.append( '<option value="'+filterData+'">'+filterData+'</option>' );
								if(filterData!='') filterDataArray.push(filterData);
							});
							// In case there was html tags we need to sort the data again
							filterDataArray.sort();
							filterDataArray.forEach(element => {
								select.append( '<option value="'+element+'">'+element+'</option>' );
							});
						});
					}
				}
			});
		}
		// Conditionaly ordering now
		if(typeof orderCol!=='undefined' && orderCol!=='') myTable.order([ orderCol, orderWay ]).draw(); // Type comparison here is important because of col zero
		// myTable.fixedHeader.adjust();
		// if(globals.isMobile) myTable.fixedHeader.disable();
		App.refreshMenuDisplay();
	},
	downloadFiles: function (link, name, thisBtn, event) {
		event.preventDefault();
		$(thisBtn).attr("disabled", true);
		const a = $("<a>").attr("href", link).attr("download", name).appendTo("footer");
		a[0].click();
		a.remove();
		$(thisBtn).attr("disabled", false);
	},
	resetFilters: function() {
		$('.table-filter').each(function() {
			$(this).val('');
		}).trigger('change');
	},
	setSalesListPage: function(action, salesListName, refresh) {
		$('#'+action).addClass('active');
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, site: globals.site, pwd: globals.pwd, ap: globals.adminPass, req: 'salesController', action: action}, function(data){
			$("#salesListCont").empty().append(data.snippet);
		}, "json").done(function(data) {
			if(action=='getMySalesList') App.buildDataTables('#dataTableSalesList', 50, true, 1, 'desc', 'Mozart Autos - Liste de mes Ventes', [0,1,2,3,4,8], [5,6,7,9], '', '', '', [0,1,2,3,4,5,6,7], true);
			else App.buildDataTables('#dataTableSalesList', 50, true, 1, 'desc', 'Mozart Autos - Liste des Ventes réalisées', [0,1,2,3,4,8], [5,6,7,9], '', '', '', [0,1,2,3,4,5,6,7,8], true);
		}).always(function(){
			// App.setupSumGroups('#saleForm');
			// App.getFormsSelectBoxes(['#saleForm', '#saleForm'], 'salesController', ['AgenceGestionSale','AgenceFacturationSale', 'idReferentSale', 'TypeInternet1', 'TypeInternet2', 'TypeInternet3', 'TypeInternet4', 'OperateurInternet1', 'OperateurInternet2', 'OperateurInternet3', 'OperateurInternet4', 'FireWall', 'OperateurSale1', 'OperateurSale2', 'TypeLienOperateur1', 'TypeLienOperateur2', 'PABX', 'TypeLicence3CX', 'TrunkProvider3CX', 'TypeLicenceM365_1', 'TypeLicenceM365_2', 'TypeLicenceM365_3', 'MarqueCopieur', 'MarqueCopieur2', 'ConcurrentCopieur', 'ConcurrentCopieur2']);
		});
		if(!refresh) {
			$('#salesListName').empty().append('<i class="fa fa-tasks"></i><b>&nbsp;'+salesListName+'</b>');
			$('#salesListSubTitle').empty().append('<i class="fa fa-tasks"></i><b>&nbsp; '+salesListName+'</b>');
			$('#salesListRefresher').attr('onclick', 'App.setSalesListPage(\''+action+'\', \''+salesListName+'\', true)');
		}
	},
	/**
	 * @param {string} selectId "#mySelectId"
	 * @param {Array} data First key of object should be value, second should be display name [{id: 1, Name: Nom}, {id: 2, Name: Other}]
	 */
	fillSelect: function (selectId, data) {
		const select = document.querySelector(selectId);
		select.innerHTML = '';
		const option = document.createElement('option');
		option.setAttribute('selected','');
		option.innerHTML = "Sélectionnez un élément";
		data.forEach(elem => {
			// console.log(elem)
			const option = document.createElement('option');
			option.value = elem[Object.keys(elem)[0]];
			option.innerHTML = elem[Object.keys(elem)[1]];
			select.appendChild(option);
		})
	},
	setOptionsListPage: async function () {
		const brandsModal = document.getElementById('brandsModal');
		const modelsModal = document.getElementById('modelsModal');
		const originsModal = document.getElementById('originsModal');
		const sitesModal = document.getElementById('sitesModal');
		const finCorpModal = document.getElementById('finCorpModal');
		const finAmModal = document.getElementById('finAmModal');

		brandsModal.addEventListener('show.bs.modal', event => {
			const button = event.relatedTarget;
			
			const id = button.getAttribute('data-bs-id');
			const name = button.getAttribute('data-bs-name');
			const active = button.getAttribute('data-bs-active');
			
			const modalId = brandsModal.querySelector('#brandId');
			const modalName = brandsModal.querySelector('#brandName');
			const modalActive = brandsModal.querySelector('#activeBrand');

			modalId.value = id;
			modalName.value = name;
			modalActive.checked = Boolean(Number(active));
		})
		modelsModal.addEventListener('show.bs.modal', event => {
			const button = event.relatedTarget;
			
			const id = button.getAttribute('data-bs-id');
			const name = button.getAttribute('data-bs-name');
			const marge = button.getAttribute('data-bs-marge');
			const prepa = button.getAttribute('data-bs-prepa');
			const transport = button.getAttribute('data-bs-transport');
			const marque = button.getAttribute('data-bs-marque');
			const active = button.getAttribute('data-bs-active');
			
			const modalId = modelsModal.querySelector('#modelId');
			const modalName = modelsModal.querySelector('#modelName');
			const modalMarge = modelsModal.querySelector('#modelMarge');
			const modalPrepa = modelsModal.querySelector('#modelPrepa');
			const modalTransport = modelsModal.querySelector('#modelTransport');
			const modalMarque = modelsModal.querySelector('#brandOfNewModel');
			const modalActive = modelsModal.querySelector('#activeModel');

			modalId.value = id;
			modalName.value = name;
			modalMarge.value = Number(marge);
			modalPrepa.value = Number(prepa);
			modalTransport.value = Number(transport);
			modalMarque.value = marque;
      modalActive.checked = Boolean(Number(active))
		})
		originsModal.addEventListener('show.bs.modal', event => {
			const button = event.relatedTarget;
			
			const lib = button.getAttribute('data-bs-lib');
			
			const modalName = originsModal.querySelector('#originName');

			modalName.value = lib;
		})
		sitesModal.addEventListener('show.bs.modal', event => {
			const button = event.relatedTarget;
			
			const id = button.getAttribute('data-bs-id');
			const name = button.getAttribute('data-bs-name');
			const active = button.getAttribute('data-bs-active');
			
			const modalId = sitesModal.querySelector('#siteId');
			const modalName = sitesModal.querySelector('#siteName');
			const modalActive = sitesModal.querySelector('#activeSite');

			modalId.value = id;
			modalName.value = name;
			modalActive.checked = Boolean(Number(active));
		})
    finCorpModal.addEventListener('show.bs.modal', event => {
      const button = event.relatedTarget;
			
			const id = button.getAttribute('data-bs-id');
			const name = button.getAttribute('data-bs-name');
			const active = button.getAttribute('data-bs-active');
			
			const modalId = finCorpModal.querySelector('#finCorpId');
			const modalName = finCorpModal.querySelector('#finCorpName');
			const modalActive = finCorpModal.querySelector('#activeFinCorp');

			modalId.value = id;
			modalName.value = name;
			modalActive.checked = Boolean(Number(active));
    })
    finAmModal.addEventListener('show.bs.modal', event => {
      const button = event.relatedTarget;
			
			const id = button.getAttribute('data-bs-id');
			const name = button.getAttribute('data-bs-name');
			const societe = button.getAttribute('data-bs-societe');
			const active = button.getAttribute('data-bs-active');
			
			const modalId = finAmModal.querySelector('#finAmId');
			const modalName = finAmModal.querySelector('#finAmName');
			const modalSociete = finAmModal.querySelector('#tauxSociete');
			const modalActive = finAmModal.querySelector('#activeFinAm');
      // console.log(modalActive)
			modalId.value = id;
			modalName.value = name;
      modalSociete.value = societe;
			modalActive.checked = Boolean(Number(active));
    })


		const bodyFormData = new FormData();
		bodyFormData.append("req", "salesController");
		bodyFormData.append("action", "getMarques");
		bodyFormData.append("id", globals.id);
		bodyFormData.append("pwd", globals.pwd);
		fetch(globals.serverAddress, {
			body: bodyFormData,
			method: "post"
		}).then(data => data.json())
		.then(data => {
			// console.log(data);
			App.fillSelect('#brandOfNewModel', data)
			const table = document.createElement('table');
			table.classList.add('table');
			table.id = 'brandsTable';
			const thead = document.createElement('thead');
			const tbody = document.createElement('tbody');
			const row = document.createElement('tr');
			for(let i of Object.keys(data[0])) {
				const cell = document.createElement('th');
				cell.innerText = i;
				row.appendChild(cell);
			}
			const cell = document.createElement('th');
			cell.innerText = 'Modifier';
			cell.classList.add('filter-false');
			row.appendChild(cell);
			thead.appendChild(row)
			data.forEach(elem => {
				const row = document.createElement('tr');
				for(let i of Object.keys(elem)) {
					const cell = document.createElement('td');
					cell.innerText = elem[i];
					cell.style.minWidth = 'fit-content';
					row.appendChild(cell);
				}
				const lastCell = document.createElement('td');
				lastCell.classList.add('btn-group');
				const modBtn = document.createElement('button');
				modBtn.classList.add('btn')
				modBtn.classList.add('btn-primary')
				modBtn.classList.add('btn-icon')
				modBtn.classList.add('shadow')
				modBtn.setAttribute('data-bs-toggle', 'modal');
				modBtn.setAttribute('data-bs-target', '#brandsModal');
				modBtn.setAttribute('data-bs-id', elem.id);
				modBtn.setAttribute('data-bs-name', elem.Nom);
				modBtn.setAttribute('data-bs-active', elem.actif);
				modBtn.innerHTML = '<svg class="svg-inline--fa fa-pen-to-square fa-2x" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen-to-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"></path></svg>';
				lastCell.appendChild(modBtn);
				row.appendChild(lastCell);
				tbody.appendChild(row);
			});
			table.appendChild(thead);
			table.appendChild(tbody);
			document.querySelector('#brandsList').innerHTML = '';
			document.querySelector('#brandsList').appendChild(table);
			App.buildDataTables('#brandsTable',10, false, 0, 'asc', '', [0,1], [2], '', '=', 'myFilter', [], true);
		})
		.catch(error => {
			console.log(error)
		});

		bodyFormData.set('action','getModels');
		fetch(globals.serverAddress, {
			body: bodyFormData,
			method: "post"
		}).then(data => data.json())
		.then(data => {
			// console.log(data);
			const table = document.createElement('table');
			table.classList.add('table');
			table.id = 'modelsTable';
			const thead = document.createElement('thead');
			const tbody = document.createElement('tbody');
			const row = document.createElement('tr');
			for(let i of Object.keys(data[0])) {
				const cell = document.createElement('th');
				cell.innerText = i;
				row.appendChild(cell);
			}
			const cell = document.createElement('th');
			cell.innerText = 'Modifier';
			cell.classList.add('filter-false');
			row.appendChild(cell);
			thead.appendChild(row)
			data.forEach(elem => {
				const row = document.createElement('tr');
				for(let i of Object.keys(elem)) {
					const cell = document.createElement('td');
					if(typeof elem[i] == 'string') {
						cell.innerText = elem[i];
					}
					else {
						const select = document.createElement('select');
						elem[i].forEach(item => {
							const option = document.createElement('option');
							option.value = item.id
							option.innerText = item.Libelle
							select.appendChild(option);
						})
						cell.appendChild(select);
					}
					cell.style.minWidth = 'fit-content';
					row.appendChild(cell);
				}
				const lastCell = document.createElement('td');
				lastCell.classList.add('btn-group');
				const modBtn = document.createElement('button');
				modBtn.classList.add('btn')
				modBtn.classList.add('btn-primary')
				modBtn.classList.add('btn-icon')
				modBtn.classList.add('shadow')
				modBtn.setAttribute('data-bs-toggle', 'modal');
				modBtn.setAttribute('data-bs-target', '#modelsModal');
				modBtn.setAttribute('data-bs-id', elem.id);
				modBtn.setAttribute('data-bs-name', elem.Nom);
				modBtn.setAttribute('data-bs-marge', elem.Marge);
				modBtn.setAttribute('data-bs-prepa', elem.Prepa);
				modBtn.setAttribute('data-bs-transport', elem.Transport);
				modBtn.setAttribute('data-bs-marque', elem.ID_Marque);
				modBtn.setAttribute('data-bs-active', elem.actif);
				modBtn.innerHTML = '<svg class="svg-inline--fa fa-pen-to-square fa-2x" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen-to-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"></path></svg>';
				lastCell.appendChild(modBtn);
				// const supprBtn = document.createElement('button');
				// supprBtn.classList.add('btn');
				// supprBtn.classList.add('btn-danger');
				// supprBtn.classList.add('btn-icon');
				// supprBtn.classList.add('shadow');
				// supprBtn.setAttribute('data-suppr-id', elem.id);
				// supprBtn.innerHTML = "<i class='fa-solid fa-2x fa-trash'></i>";
				// supprBtn.addEventListener('click', e => {
				// 	const bodyFormData = new FormData();
				// 	bodyFormData.append("req", "salesController");
				// 	bodyFormData.append("action", "delModel");
				// 	bodyFormData.append("id", globals.id);
				// 	bodyFormData.append("pwd", globals.pwd);
				// 	bodyFormData.append('delId', e.relatedTarget.getAttribute('data-suppr-id'));
				// 	fetch(globals.serverAddress, {
				// 		body: bodyFormData,
				// 		method: 'post'
				// 	})
				// 	.then(res => res.json())
				// 	.then(data => {
				// 		console.log(data);
				// 	})
				// 	.catch(err => {
				// 		console.error(err)
				// 	})
				// })
				// lastCell.appendChild(supprBtn)
				row.appendChild(lastCell);
				tbody.appendChild(row);
			});
			table.appendChild(thead);
			table.appendChild(tbody);
			document.querySelector('#modelsList').innerHTML = '';
			document.querySelector('#modelsList').appendChild(table);
			App.buildDataTables('#modelsTable',10, false, 0, 'asc', '', [0,1,2,3,4,5], [6,7], '', '=', 'myFilter', [], true);
		})
		.catch(error => {
			console.log(error)
		});
		
		
		
		
		bodyFormData.set('action','getOrigins');
		fetch(globals.serverAddress, {
			body: bodyFormData,
			method: "post"
		}).then(data => data.json())
		.then(data => {
			// console.log(data);
			const table = document.createElement('table');
			table.classList.add('table');
			table.id = 'originsTable';
			const thead = document.createElement('thead');
			const tbody = document.createElement('tbody');
			const row = document.createElement('tr');
			for(let i of Object.keys(data[0])) {
				const cell = document.createElement('th');
				cell.innerText = i;
				row.appendChild(cell);
			}
			const cell = document.createElement('th');
			cell.innerText = 'Modifier';
			cell.classList.add('filter-false');
			row.appendChild(cell);
			thead.appendChild(row)
			data.forEach(elem => {
				const row = document.createElement('tr');
				for(let i of Object.keys(elem)) {
					const cell = document.createElement('td');
					if(typeof elem[i] == 'string') {
						cell.innerText = elem[i];
					}
					else {
						const select = document.createElement('select');
						elem[i].forEach(item => {
							const option = document.createElement('option');
							option.value = item.Libelle;
							option.innerText = item.Libelle
							select.appendChild(option);
						})
						cell.appendChild(select)
					}
					row.appendChild(cell);
				}
				const lastCell = document.createElement('td');
				lastCell.classList.add('btn-group');
				const modBtn = document.createElement('button');
				modBtn.classList.add('btn')
				modBtn.classList.add('btn-primary')
				modBtn.classList.add('btn-icon')
				modBtn.classList.add('shadow')
				modBtn.setAttribute('data-bs-toggle', 'modal');
				modBtn.setAttribute('data-bs-target', '#originsModal');
				modBtn.setAttribute('data-bs-lib', elem.Libelle);
				modBtn.innerHTML = '<svg class="svg-inline--fa fa-pen-to-square fa-2x" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen-to-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"></path></svg>';
				lastCell.appendChild(modBtn);
				// const supprBtn = document.createElement('button');
				// supprBtn.classList.add('btn');
				// supprBtn.classList.add('btn-danger');
				// supprBtn.classList.add('btn-icon');
				// supprBtn.classList.add('shadow');
				// supprBtn.setAttribute('data-suppr-id', elem.Libelle);
				// supprBtn.innerHTML = "<i class='fa-solid fa-2x fa-trash'></i>";
				// supprBtn.addEventListener('click', e => {
				// 	const bodyFormData = new FormData();
				// 	bodyFormData.append("req", "salesController");
				// 	bodyFormData.append("action", "delOrigine");
				// 	bodyFormData.append("id", globals.id);
				// 	bodyFormData.append("pwd", globals.pwd);
				// 	bodyFormData.append('delId', e.relatedTarget.getAttribute('data-suppr-id'));
				// 	fetch(globals.serverAddress, {
				// 		body: bodyFormData,
				// 		method: 'post'
				// 	})
				// 	.then(res => res.json())
				// 	.then(data => {
				// 		console.log(data);
				// 	})
				// 	.catch(err => {
				// 		console.error(err)
				// 	})
				// })
				// lastCell.appendChild(supprBtn)
				row.appendChild(lastCell);
				tbody.appendChild(row);
			});
			table.appendChild(thead);
			table.appendChild(tbody);
			document.querySelector('#originsList').innerHTML = '';
			document.querySelector('#originsList').appendChild(table);
			App.buildDataTables('#originsTable',10, false, 0, 'asc', '', [0], [], '', '=', 'myFilter', [], true);
		})
		.catch(error => {
			console.log(error)
		});
		
		
		
		
		
		
		bodyFormData.set('action','getSites');
		fetch(globals.serverAddress, {
			body: bodyFormData,
			method: "post"
		}).then(data => data.json())
		.then(data => {
			// console.log(data);
			const table = document.createElement('table');
			table.classList.add('table');
			table.id = 'sitesTable';
			const thead = document.createElement('thead');
			const tbody = document.createElement('tbody');
			const row = document.createElement('tr');
			for(let i of Object.keys(data[0])) {
				const cell = document.createElement('th');
				cell.innerText = i;
				row.appendChild(cell);
			}
			const cell = document.createElement('th');
			cell.innerText = 'Modifier';
			cell.classList.add('filter-false');
			row.appendChild(cell);
			thead.appendChild(row)
			data.forEach(elem => {
				const row = document.createElement('tr');
				for(let i of Object.keys(elem)) {
					const cell = document.createElement('td');
					if(typeof elem[i] == 'string') {
						cell.innerText = elem[i];
					}
					else {
						const select = document.createElement('select');
						elem[i].forEach(item => {
							const option = document.createElement('option');
							option.value = item.id
							option.innerText = item.Libelle
							select.appendChild(option);
						})
						cell.appendChild(select)
					}
					row.appendChild(cell);
				}
				const lastCell = document.createElement('td');
				lastCell.classList.add('btn-group');
				const modBtn = document.createElement('button');
				modBtn.classList.add('btn')
				modBtn.classList.add('btn-primary')
				modBtn.classList.add('btn-icon')
				modBtn.classList.add('shadow')
				modBtn.setAttribute('data-bs-toggle', 'modal');
				modBtn.setAttribute('data-bs-target', '#sitesModal');
				modBtn.setAttribute('data-bs-id', elem.id);
				modBtn.setAttribute('data-bs-name', elem.Nom);
				modBtn.setAttribute('data-bs-active', elem.actif);
				modBtn.innerHTML = '<svg class="svg-inline--fa fa-pen-to-square fa-2x" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen-to-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"></path></svg>';
				lastCell.appendChild(modBtn);
				// const supprBtn = document.createElement('button');
				// supprBtn.classList.add('btn');
				// supprBtn.classList.add('btn-danger');
				// supprBtn.classList.add('btn-icon');
				// supprBtn.classList.add('shadow');
				// supprBtn.setAttribute('data-suppr-id', elem.id);
				// supprBtn.innerHTML = "<i class='fa-solid fa-2x fa-trash'></i>";
				// supprBtn.addEventListener('click', e => {
				// 	console.log('suppr')
				// })
				// lastCell.appendChild(supprBtn)
				row.appendChild(lastCell);
				tbody.appendChild(row);
			});
			table.appendChild(thead);
			table.appendChild(tbody);
			document.querySelector('#sitesList').innerHTML = '';
			document.querySelector('#sitesList').appendChild(table);
			App.buildDataTables('#sitesTable',10, false, 0, 'asc', '', [0,2], [1], '', '=', 'myFilter', [], true);
		})
		.catch(error => {
			console.log(error)
		});





		bodyFormData.set("action", "getFinCorps");
		fetch(globals.serverAddress, {
			body: bodyFormData,
			method: "post"
		}).then(data => data.json())
		.then(data => {
			// console.log(data);
			App.fillSelect('#tauxSociete', data)
			const table = document.createElement('table');
			table.classList.add('table');
			table.id = 'finCorpTable';
			const thead = document.createElement('thead');
			const tbody = document.createElement('tbody');
			const row = document.createElement('tr');
			for(let i of Object.keys(data[0])) {
				const cell = document.createElement('th');
				cell.innerText = i;
				row.appendChild(cell);
			}
			const cell = document.createElement('th');
			cell.innerText = 'Modifier';
			cell.classList.add('filter-false');
			row.appendChild(cell);
			thead.appendChild(row)
			data.forEach(elem => {
				const row = document.createElement('tr');
				for(let i of Object.keys(elem)) {
					const cell = document.createElement('td');
					cell.innerText = elem[i];
					cell.style.minWidth = 'fit-content';
					row.appendChild(cell);
				}
				const lastCell = document.createElement('td');
				lastCell.classList.add('btn-group');
				const modBtn = document.createElement('button');
				modBtn.classList.add('btn')
				modBtn.classList.add('btn-primary')
				modBtn.classList.add('btn-icon')
				modBtn.classList.add('shadow')
				modBtn.setAttribute('data-bs-toggle', 'modal');
				modBtn.setAttribute('data-bs-target', '#finCorpModal');
				modBtn.setAttribute('data-bs-id', elem.id);
				modBtn.setAttribute('data-bs-name', elem.Nom);
				modBtn.setAttribute('data-bs-active', elem.actif);
				modBtn.innerHTML = '<svg class="svg-inline--fa fa-pen-to-square fa-2x" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen-to-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"></path></svg>';
				lastCell.appendChild(modBtn);
				row.appendChild(lastCell);
				tbody.appendChild(row);
			});
			table.appendChild(thead);
			table.appendChild(tbody);
			document.querySelector('#finCorpList').innerHTML = '';
			document.querySelector('#finCorpList').appendChild(table);
			App.buildDataTables('#finCorpTable',10, false, 0, 'asc', '', [0,1], [2], '', '=', 'myFilter', [], true);
		})
		.catch(error => {
			console.log(error)
		});
		
    bodyFormData.set('action','getFinAm');
		fetch(globals.serverAddress, {
			body: bodyFormData,
			method: "post"
		}).then(data => data.json())
		.then(data => {
			// console.log(data);
			const table = document.createElement('table');
			table.classList.add('table');
			table.id = 'finAmTable';
			const thead = document.createElement('thead');
			const tbody = document.createElement('tbody');
			const row = document.createElement('tr');
			for(let i of Object.keys(data[0])) {
				const cell = document.createElement('th');
				cell.innerText = i;
				row.appendChild(cell);
			}
			const cell = document.createElement('th');
			cell.innerText = 'Modifier';
			cell.classList.add('filter-false');
			row.appendChild(cell);
			thead.appendChild(row)
			data.forEach(elem => {
				const row = document.createElement('tr');
				for(let i of Object.keys(elem)) {
					const cell = document.createElement('td');
					if(typeof elem[i] == 'string') {
						cell.innerText = elem[i];
					}
					else {
						// const select = document.createElement('select');
						// elem[i].forEach(item => {
						// 	const option = document.createElement('option');
						// 	option.value = item.id
						// 	option.innerText = item.Libelle
						// 	select.appendChild(option);
						// })
						// cell.appendChild(select);
            console.log(elem)
					}
					cell.style.minWidth = 'fit-content';
					row.appendChild(cell);
				}
				const lastCell = document.createElement('td');
				lastCell.classList.add('btn-group');
				const modBtn = document.createElement('button');
				modBtn.classList.add('btn')
				modBtn.classList.add('btn-primary')
				modBtn.classList.add('btn-icon')
				modBtn.classList.add('shadow')
				modBtn.setAttribute('data-bs-toggle', 'modal');
				modBtn.setAttribute('data-bs-target', '#finAmModal');
				modBtn.setAttribute('data-bs-id', elem.id);
				modBtn.setAttribute('data-bs-name', elem.Nom);
				modBtn.setAttribute('data-bs-active', elem.actif);
				modBtn.setAttribute('data-bs-societe', elem.ID_Societe);
				modBtn.innerHTML = '<svg class="svg-inline--fa fa-pen-to-square fa-2x" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen-to-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"></path></svg>';
				lastCell.appendChild(modBtn);
				row.appendChild(lastCell);
				tbody.appendChild(row);
			});
			table.appendChild(thead);
			table.appendChild(tbody);
			document.querySelector('#finAmList').innerHTML = '';
			document.querySelector('#finAmList').appendChild(table);
			App.buildDataTables('#finAmTable',10, false, 0, 'asc', '', [0,1,2], [3], '', '=', 'myFilter', [], true);
		})
		.catch(error => {
			console.log(error)
		});
	},
	editBrand: function() {
		const form = document.querySelector('#brandFormEdit');
		const bodyFormData = new FormData(form);
		bodyFormData.append("req", "salesController");
		bodyFormData.append("action", "putMarque");
		bodyFormData.append("id", globals.id);
		bodyFormData.append("pwd", globals.pwd);
		if(bodyFormData.get('brandActive') == null) {
			bodyFormData.set('brandActive', 0)
		}
		else {
			bodyFormData.set('brandActive', 1)
		}
		for(const key of bodyFormData.keys()) {
			console.log(key, bodyFormData.get(key));
		}
		fetch(globals.serverAddress, {
			method: "post",
			body: bodyFormData
		})
		.then(res => res.json())
		.then(data => {
			// console.log(data);
			// App.closeModal('#brandsModal');
			// const myModalObject = document.getElementById("brandsModal");
			// if (myModalObject) {
			// 	let myModal = new bootstrap.Modal(myModalObject);
			// 	myModal.hide();
			// }
			App.setOptionsListPage();
		})
		.catch(err => {
			console.error(err);
			alert('Une erreur est survenue');
		})
		
	},
	editModel: function() {
		const form = document.querySelector('#modelFormEdit');
		const bodyFormData = new FormData(form);
		bodyFormData.append("req", "salesController");
		bodyFormData.append("action", "putModel");
		bodyFormData.append("id", globals.id);
		bodyFormData.append("pwd", globals.pwd);
    if(bodyFormData.get('activeModel') == null) {
			bodyFormData.set('activeModel', 0)
		}
		else {
			bodyFormData.set('activeModel', 1)
		}
		for(const key of bodyFormData.keys()) {
			console.log(key, bodyFormData.get(key));
		}
		fetch(globals.serverAddress, {
			method: "post",
			body: bodyFormData
		})
		.then(res => res.json())
		.then(data => {
			// console.log(data);
			// App.closeModal('#modelsModal');
			// const myModalObject = document.getElementById("modelsModal");
			// if (myModalObject) {
			// 	let myModal = new bootstrap.Modal(myModalObject);
			// 	myModal.hide();
			// }
			App.setOptionsListPage();
		})
		.catch(err => {
			console.error(err);
			alert('Une erreur est survenue');
		})
		
	},
	editOrigin: function() {
		const form = document.querySelector('#originFormEdit');
		const bodyFormData = new FormData(form);
		bodyFormData.append("req", "salesController");
		bodyFormData.append("action", "putOrigin");
		bodyFormData.append("id", globals.id);
		bodyFormData.append("pwd", globals.pwd);
		for(const key of bodyFormData.keys()) {
			console.log(key, bodyFormData.get(key));
		}
		fetch(globals.serverAddress, {
			method: "post",
			body: bodyFormData
		})
		.then(res => res.json())
		.then(data => {
			// console.log(data);
			// App.closeModal('#originsModal');
			// const myModalObject = document.getElementById("originsModal");
			// if (myModalObject) {
			// 	let myModal = new bootstrap.Modal(myModalObject);
			// 	myModal.hide();
			// }
			App.setOptionsListPage();
		})
		.catch(err => {
			console.error(err);
			alert('Une erreur est survenue');
		})

	},
	editSite: function() {
		const form = document.querySelector('#siteFormEdit');
		const bodyFormData = new FormData(form);
		bodyFormData.append("req", "salesController");
		bodyFormData.append("action", "putSite");
		bodyFormData.append("id", globals.id);
		bodyFormData.append("pwd", globals.pwd);
		if(bodyFormData.get('activeSite') == null) {
			bodyFormData.set('activeSite', 0)
		}
		else {
			bodyFormData.set('activeSite', 1)
		}
		for(const key of bodyFormData.keys()) {
			console.log(key, bodyFormData.get(key));
		}
		fetch(globals.serverAddress, {
			method: "post",
			body: bodyFormData
		})
		.then(res => res.json())
		.then(data => {
			// console.log(data);
			// App.closeModal('#sitesModal');
			// const myModalObject = document.getElementById("sitesModal");
			// if (myModalObject) {
			// 	let myModal = new bootstrap.Modal(myModalObject);
			// 	myModal.hide();
			// }
			App.setOptionsListPage();
		})
		.catch(err => {
			console.error(err);
			alert('Une erreur est survenue');
		})
		
	},
	editFinCorp: function() {
		const form = document.querySelector('#finCorpFormEdit');
		const bodyFormData = new FormData(form);
		bodyFormData.append("req", "salesController");
		bodyFormData.append("action", "putFinCorp");
		bodyFormData.append("id", globals.id);
		bodyFormData.append("pwd", globals.pwd);
		if(bodyFormData.get('activeFinCorp') == null) {
			bodyFormData.set('activeFinCorp', 0)
		}
		else {
			bodyFormData.set('activeFinCorp', 1)
		}
		for(const key of bodyFormData.keys()) {
			console.log(key, bodyFormData.get(key));
		}
		fetch(globals.serverAddress, {
			method: "post",
			body: bodyFormData
		})
		.then(res => res.json())
		.then(data => {
			// console.log(data);
			// App.closeModal('#sitesModal');
			// const myModalObject = document.getElementById("sitesModal");
			// if (myModalObject) {
			// 	let myModal = new bootstrap.Modal(myModalObject);
			// 	myModal.hide();
			// }
			App.setOptionsListPage();
		})
		.catch(err => {
			console.error(err);
			alert('Une erreur est survenue');
		})
		
	},
	editFinAm: function() {
		const form = document.querySelector('#finAmFormEdit');
		const bodyFormData = new FormData(form);
		bodyFormData.append("req", "salesController");
		bodyFormData.append("action", "putFinAm");
		bodyFormData.append("id", globals.id);
		bodyFormData.append("pwd", globals.pwd);
		if(bodyFormData.get('activeFinAm') == null) {
			bodyFormData.set('activeFinAm', 0)
		}
		else {
			bodyFormData.set('activeFinAm', 1)
		}
		for(const key of bodyFormData.keys()) {
			console.log(key, bodyFormData.get(key));
		}
		fetch(globals.serverAddress, {
			method: "post",
			body: bodyFormData
		})
		.then(res => res.json())
		.then(data => {
			// console.log(data);
			// App.closeModal('#sitesModal');
			// const myModalObject = document.getElementById("sitesModal");
			// if (myModalObject) {
			// 	let myModal = new bootstrap.Modal(myModalObject);
			// 	myModal.hide();
			// }
			App.setOptionsListPage();
		})
		.catch(err => {
			console.error(err);
			alert('Une erreur est survenue');
		})
		
	},
	setSalePage: async function(idSale) {
		this.fillModSale(idSale, null, '#saleForm', 'saleModal');
		App.setSalesListPage('getSalesList', 'Les 1000 dernières ventes', false);
		/*
		const myFormDiv = '#modSaleForm';
		App.clearFormFields(myFormDiv);
		await App.setupSumGroups(myFormDiv);
		$('#headerSalePage').text(idSale);
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, ap: globals.adminPass, idSale: idSale, req: 'salesController', action: 'fillModSale'}, function(data){
			if(data.ok=="ok") {
				for (const [key, value] of Object.entries(data.sales)) {
					// $(myFormDiv+' #'+key).val(value);
					let target = $(myFormDiv+' #'+key);
					if(target.is('select')) { // Dealing with selects
						// target[0].value = value;
						target.val(value);
						const eventOnChange = target.attr('onchange');
						let newEvent;
						if(!!eventOnChange) {
							switch(key) {
								case 'site':
									newEvent = eventOnChange.replace('this', `this, ${data.sales.seller}`);
									target.attr('onchange', newEvent).trigger('change');
								break;
								case 'brand':
									newEvent = eventOnChange.replace('this', `this, ${data.sales.model}`);
									target.attr('onchange', newEvent).trigger('change');
								break;
							}
						}
					}
					else if(target.is('input[type=checkbox]') && value==1) $(myFormDiv+' #'+key).prop('checked', true); // Dealing with Checkboxes
					else {
						$(myFormDiv+' #'+key).val(value);
					}
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé cette vente !");
		}, "json").always(function(){
		});
		*/
	},
	fillModSale: async function(idSale, thisBtn, myFormDiv, myFormModal) {
		if(thisBtn) $(thisBtn).attr("disabled", true).html('<i class="fa fa-2x fa-spinner fa-pulse"></i>');
		sessionStorage.setItem('currentForm', 'mod')
		globals.sales.currentModSale = idSale;
		// await App.setupSumGroups('#saleForm', 'mod')
		// App.clearFormFields(myFormDiv);
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, ap: globals.adminPass, idSale: idSale, req: 'salesController', action: 'fillModSale'}, function(data){
			if(data.ok=="ok") {
        let suffixe;
        if(myFormDiv == '#changeSaleVOForm') {
          suffixe = '-3'
        }
        else if(myFormDiv == "#changeSaleForm") {
          suffixe = '-2'
        }
				for (let [key, value] of Object.entries(data.sales)) {
					let target = $(myFormDiv+' #'+key+suffixe);
					console.log(target[0])
					if(target.is('select')) { // Dealing with selects
						// target[0].value = value;
						target.val(value);
						const eventOnChange = target.attr('onchange');
						let newEvent;
						if(!!eventOnChange) {
							switch(key) {
								case 'site':
									newEvent = eventOnChange.replace('this', `this, ${data.sales.seller}`);
									target.attr('onchange', newEvent).trigger('change');
								break;
								case 'brand':
									newEvent = eventOnChange.replace('this', `this, ${data.sales.model}`);
									target.attr('onchange', newEvent).trigger('change');
								break;
							}
						}
						/*
						if(Array.from(target[0].childNodes).find(child => child.value == value) == undefined) {
							elemsToFill[target[0].id] = value;
						}
						const eventToEval = target[0].getAttribute('onchange');
						if(!!eventToEval) {
							const newEvent = eventToEval.replace('this', `'#${target[0].id}'`);
							eval(newEvent);
						}
						*/
					}
					else if(target.is('input[type=checkbox]') && value==1) $(myFormDiv+' #'+key).prop('checked', true); // Dealing with Checkboxes
					else {
						if(!isNaN(value) && target.attr('type') == 'number') {
							value = Math.floor(value * 100) / 100;
							// value = App.number_format(value, 2, '.', ' ');
						}
						// console.log(target.attr('id'), value)
						target.val(value);
					}
				}
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé ce client !");
		}, "json").always(function(){
			if(thisBtn) $(thisBtn).attr("disabled", false).html('<i class="fa fa-2x fa-edit"></i>');
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
		});
	},
  delReprise: function() {
    const repriseRow = document.querySelector('#reprise-row');
    repriseRow.querySelectorAll('input[type=text], input[type=date]').forEach(elem => elem.value = '');
    const repriseVORow = document.querySelector('#repriseVO-row');
    repriseVORow.querySelectorAll('input[type=text], input[type=date]').forEach(elem => elem.value = '');

  },
  changeCom: function(thisBtn) {
    const hullCycle = [
      {
        name: 'SANS',
        com: 0
      },
      {
        name: 'POWER SHINE',
        com: 20
      },
      {
        name: 'WAXOYL',
        com: 100
      }
    ]
    const input = thisBtn.parentNode.querySelector('input');
    console.log(input)
    console.log(input.value)
    if(input.value == 1 || input.value == 0) {
      console.log("val is 1 or 0")
      if(input.value == 1) {
        console.log("val is 1")
        input.value = 0
      }
      else {
        console.log("val is 0")
        input.value = 1
      }
    }
    else {

    }
  },
	modSale: function(myFormDiv, fromModal) {
		const bodyFormData = new FormData();
		bodyFormData.append("req", "salesController");
		bodyFormData.append("action", "modSale");
		bodyFormData.append("id", globals.id);
		bodyFormData.append("pwd", globals.pwd);
		bodyFormData.append("idSale", globals.sales.currentModSale);

    const newChassis = document.querySelector('#chassis-2').value
    const newImmat = document.querySelector('#immat-2').value
    const newNumCommande = document.querySelector('#numCommande-2').value

    const newDateReel = document.querySelector('#date_reel-2').value

    const delRep = document.querySelector('#reprise-2').value == ''

    const newMarginControl = document.querySelector('#benefHTReel-2').value

    bodyFormData.append("newChassis", newChassis)
    bodyFormData.append("newImmat", newImmat)
    bodyFormData.append("newNumCommande", newNumCommande)
    bodyFormData.append("newDateReel", newDateReel)
    bodyFormData.append("delRep", String(delRep))
    bodyFormData.append("newMarginControl", newMarginControl)
    bodyFormData.append("auto_v", globals.sales.currentModSale)

		
		// $(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		// let query = $(myFormDiv).serialize();
		// query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=salesController&action=modSale";
		// let returns = "";


		fetch(globals.serverAddress, {
			body: bodyFormData,
			/*
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Content-Type": "multipart/form-data",
			},
			*/
			method: "post"
		})
		.then(data => data.json())
		.then(data => {
			//do Something
			console.log(data)
			if(data.status == 200) {
				App.setSalesListPage('getSalesList');
        setTimeout(function(){
          $(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
          // App.clearFormFields(myFormDiv);
        }, 1600);
				// App.closeModal('#saleModal');
				// const myModalObject = document.getElementById("saleModal");
				// if (myModalObject) {
				// 	let myModal = new bootstrap.Modal(myModalObject);
				// 	myModal.hide();
				// }
			}
			else {
				alert('Erreur lors de la mise à jour de la vente');
			}
		})
		.catch(error => {
			alert('Erreur lors de la mise à jour de la vente');
			console.log(error)
		})




		// $.post(globals.serverAddress, query, function(data){
		// 	if(data.ok=="ok") {
		// 		App.closeModal('#saleModal');
		// 		returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle"></i>&nbsp;La vente a bien été mise à jour.</b></div>';
		// 		if(fromModal===true) {
		// 			App.setSalesListPage();
		// 			setTimeout(function(){
		// 				$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
		// 				App.clearFormFields(myFormDiv);
		// 			}, 2600);
		// 		}
		// 	}
		// 	else
		// 		returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i>&nbsp;La vente n\'a pas été modifiée suite à un problème technique.</b></div>';
		// }, "json").always(function(data){
		// 	$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-check-circle"></i>&nbsp;Enregistrer');
		// 	$(myFormDiv+' #successfail').empty().append(returns);
		// });
	},
	addSale: function(form) {
		// const form = document.getElementById('saleModal').querySelector('form');
		if(sessionStorage.getItem('currentForm') == 'mod') {
			App.modSale(form);
			return 0;
		}
		const bodyFormData = new FormData();
		bodyFormData.append("req", "salesController");
		bodyFormData.append("action", "addSale");
		bodyFormData.append("id", globals.id);
		bodyFormData.append("pwd", globals.pwd);
		let car = {};
		let client = {};
    let com = {}; // commission
    let rep = {}; // reprise
		document.querySelector(form).querySelectorAll('[data-name]').forEach(input => {
			if(input.tagName == "SELECT") {
				let val = input.value;
				// let val = input.querySelector(`option[value="${input.value}"]`)?.innerHTML ?? 0;
				if(input.hasAttribute('data-car')) {
					car[input.getAttribute('data-name')] = val;
				}
				else if (input.hasAttribute('data-client')) {
					client[input.getAttribute('data-name')] = val;
				}
        else if (input.hasAttribute('data-com')) {
          com[input.getAttribute('data-name')] = val;
        }
        else if (input.hasAttribute('data-reprise')) {
          rep[input.getAttribute('data-name')] = val;
        }
				else {
					if(bodyFormData.get(input.getAttribute('data-name')) != null) {
						bodyFormData.set(input.getAttribute('data-name'), Number(bodyFormData.get(input.getAttribute('data-name'))) + val)
					}
					else {
						bodyFormData.append(input.getAttribute('data-name'), val)
					}
				}
			}
			else if (input.tagName == "CANVAS") {
				bodyFormData.append(input.getAttribute('data-name'), input.toDataURL('image/png'))
			}
			else {
				if(input.hasAttribute('data-car')) {
					let val = input.value;
					car[input.getAttribute('data-name')] = val;
				}
				else if (input.hasAttribute('data-client')) {
					let val = input.value;
					client[input.getAttribute('data-name')] = val;
				}
        else if (input.hasAttribute('data-com')) {
          let val = input.value;
          com[input.getAttribute('data-name')] = val;
        }
        else if (input.hasAttribute('data-reprise')) {
          let val = input.value;
          rep[input.getAttribute('data-name')] = val;
        }
				else {
					if(input.type == 'checkbox') {
						bodyFormData.append(input.getAttribute('data-name'), input.checked)
					}
					else {
            if(bodyFormData.get(input.getAttribute('data-name')) != null) {
              bodyFormData.set(input.getAttribute('data-name'), Number(bodyFormData.get(input.getAttribute('data-name'))) + Number(input.value))
            }
            else {
              bodyFormData.append(input.getAttribute('data-name'), input.value)
            }
					}
				}
			}
		})
		bodyFormData.append('car', JSON.stringify(car))
		bodyFormData.append('client', JSON.stringify(client))
		bodyFormData.append('com', JSON.stringify(com))
		bodyFormData.append('rep', JSON.stringify(rep))
		fetch(globals.serverAddress, {
			body: bodyFormData,
			/*
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
				"Content-Type": "multipart/form-data",
			},
			*/
			method: "post"
		})
		.then(data => data.json())
		.then(data => {
			//do Something
			console.log(data)
			if(data.status == 200) {
				App.clearFormFields(form);
				// App.closeModal('#saleModal');
				// const myModalObject = document.getElementById("saleModal");
				// if (myModalObject) {
				// 	let myModal = new bootstrap.Modal(myModalObject);
				// 	console.log(myModal)
				// 	myModal.hide();
				// }
				App.setSalesListPage('getMySalesList', 'Mes ventes', true);
        globals.sales.sumGroup = {}
        location.reload()
			}
			else {
				alert('Erreur lors de l\'insertion de la vente');
			}
		})
		.catch(error => {
			alert('Erreur lors de l\'insertion de la vente');
			console.log(error)
		})
		// globals.sales.modal.hide();
		return 0;
		/*
		const myFormDiv = '#'+$(form).attr('id');
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=salesController&action=addSale";
		let returns = "";
		//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle"></i>&nbsp;La vente a bien été ajoutée.</b></div>';
				App.setSalesListPage();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
					App.clearFormFields(myFormDiv);
				}, 2600);
			}
			else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i>&nbsp;La vente n\'a pas été ajoutée, suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-plus-circle"></i>&nbsp;Enregistrer');
			$(myFormDiv+' #successfail').empty().append(returns);
		});
		*/
	},
	delSale: function(idSale, thisBtn, myFormDiv, event) {
		//event.preventDefault(); // prevents mod form submission !
		const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer cette vente ?");
		if (confirmDeletion) {
			$(myFormDiv+' #deleter').attr("disabled", true);
			// const idSale = $(myFormDiv+' #auto_sales').val();
			const req = "salesController";
			const action = "delSale";
			let query = "idSale=" + idSale + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
			let returns = "";
			//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
			$.post(globals.serverAddress, query, function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle"></i>&nbsp;La vente a bien été supprimée.</b></div>';
					App.setSalesListPage('getSalesList');
					setTimeout(function(){
						$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
						// App.clearFormFields(myFormDiv);
					}, 1600);
				}
				else
					returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i>&nbsp;Cette vente n\'a pas été supprimée suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' #deleter').attr("disabled", false);
				$(myFormDiv+' #successfail').empty().append(returns);
			});
		}
	},
	fillModUser: function(auto_u, thisBtn, myFormDiv, myFormModal) {
		App.clearFormFields(myFormDiv);
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, ap: globals.adminPass, auto_u: auto_u, req: 'usersController', action: 'fillModUser'}, function(data){
			if(data.ok=="ok") {
        const bodyFormData = new FormData();
        bodyFormData.append('id', globals.id);
        bodyFormData.append('type', globals.type);
        bodyFormData.append('pwd', globals.pwd);
        bodyFormData.append('ap', globals.adminPass);
        bodyFormData.append('req', "salesController");
        bodyFormData.append('action', "getSites");
        fetch(globals.serverAddress, {
          method: 'post',
          body: bodyFormData
        })
        .then(res => res.json())
        .then(dataSites => {
          dataSites = dataSites.filter(site => site.actif == 1);
          console.log(dataSites)
          App.fillSelect("#site_u2", dataSites);
          for (const [key, value] of Object.entries(data.users)) {
            $(myFormDiv+' #'+key).val(value);
          }
        })
			}
			else if(data.administrator) {
				$(myFormDiv).closest('div').empty().append('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Vous n\'avez pas les droits suffisants pour afficher cette ressource !</div>');
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé cet utilisateur !");
		}, "json").always(function(){
			let myModal = new bootstrap.Modal(document.getElementById(myFormModal));
			myModal.show();
		});
		if(globals.type!='MyBelovedLord') $('.allUsersRemove').remove();
	},
	modUser: function(myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		// let query = $(myFormDiv).serialize();
		const req = "usersController";
		const action = "modUser";
		// query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", req); // req is included in form data here
		request.append("action", action); // action is included in form data here
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("ap", globals.adminPass);
		const auto_u = $(myFormDiv+' #auto_u').val();
		let returns = "";
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				if(myFormDiv=='#modUserForm') {
					returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle"></i>&nbsp;Le profil a bien été mis à jour.</b></div>';
					App.setUsersListPage();
					setTimeout(function(){
						$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
						App.clearFormFields(myFormDiv);
					}, 1600);
				}
				if(myFormDiv=='#modProfileForm' && auto_u==globals.id) { // Whoever user on his own profile
					returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle"></i>&nbsp;Le profil a bien été mis à jour.<br>Attention: Vous allez devoir vous reconnecter !</b></div>';
					setTimeout(function(){
						App.logMeOut();
					}, 2600);
				}
				else if (auto_u!=globals.id) { // Power user on someone else's profile
					returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle"></i>&nbsp;Le profil a bien été mis à jour.</b></div>';
				}
			}
			else
				returns = '<div class="alert alert-danger" role="alert"<i class="fa fa-times-circle"></i>&nbsp;Le profil n\'a pas été modifié suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-check-circle"></i>&nbsp;Enregistrer');
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},
	addUser: function(myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse"></i>&nbsp;Veuillez patienter');
		// let query = $(myFormDiv).serialize();
		const req = "usersController";
		const action = "addUser";
		// Serializing checkBoxes first for regular checkBoxes, second for Bootstrap's customs (assuming id=name)...
		//let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.name+"="+this.checked;}).get().join("&");
		//let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.id+"="+this.checked;}).get().join("&");
		//query = query + "&" + checkBoxes + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		// query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", req); // req is included in form data here
		request.append("action", action); // action is included in form data here
		request.append("id", globals.id);
		request.append("pwd", globals.pwd);
		request.append("type", globals.type);
		request.append("ap", globals.adminPass);
		let returns = "";
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle"></i>&nbsp;Le profil a bien été ajouté aux Utilisateurs.</b></div>';
				App.setUsersListPage();
				setTimeout(function(){
					$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
					App.clearFormFields(myFormDiv);
				}, 1600);
			}
			else if(data.error=="administrator") {
				returns = data.snippet;
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i>&nbsp;Le profil n\'a pas été ajouté aux Utilisateurs, suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-plus-circle"></i>&nbsp;Enregistrer');
			$(myFormDiv+' #successfail').empty().append(returns);
		});
	},
	delUser: function(myFormDiv, event) {
		event.preventDefault(); // prevents mod form submission !
		const confirmDeletion = confirm("Êtes-vous certain de vouloir supprimer ce compte Utilisateur ?");
		if (confirmDeletion) {
			$(myFormDiv+' #deleter').attr("disabled", true);
			const delId = $(myFormDiv+' #auto_u').val();
			const WpId = $(myFormDiv+' #wpid_u').val();
			const req = "usersController";
			const action = "delUser";
			let query = "auto_u=" + delId + "&wpid_u=" + WpId + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&ap=" + globals.adminPass + "&req=" + req + "&action=" + action;
			let returns = "";
			//$(myFormDiv+' #successfail').append('<div class="alert alert-success" role="alert"><b>Query : '+query+'</b></div>');
			$.post(globals.serverAddress, query, function(data){
				if(data.ok=="ok") {
					returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle"></i>&nbsp;Ce compte Utilisateur a bien été supprimée.</b></div>';
					App.setUsersListPage();
					setTimeout(function(){
						$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
						App.clearFormFields(myFormDiv);
					}, 1600);
				}
				else
					returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle"></i>&nbsp;Ce compte Utilisateur n\'a pas été supprimée suite à un problème technique.</b></div>';
			}, "json").always(function(data){
				$(myFormDiv+' #deleter').attr("disabled", false);
				$(myFormDiv+' #successfail').empty().append(returns);
			});
		}
	},
	setUsersListPage: function() {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, ap: globals.adminPass, req: 'usersController', action: 'getUsersList'}, function(data){
			if(data.error=='') $("#userListCont").empty().append(data.snippet);
			else if(globals.type=='MyBelovedLord' && data.error=='administrator') setTimeout(function(){App.setUsersListPage();}, 1000); // Retry after some time => If an admin goes directly to this page then the token might not be set !
			else $("#userListCont").empty().append(data.snippet);
		}, "json").done(function(data) {
			App.buildDataTables('#dataTableUsersList', 25, true, 12, 'desc', 'Mozart Autos - Liste des Utilisateurs', [0,3,4,5,6,7,8,9,11], [1,2,10,12], '', '', '', [0,1,2,3,4,5,6,7,8,9,10,11,12], true);
		}).always(function(){
			App.getFormsSelectBoxes(['#modUserForm'], 'usersController', ['site_u']);
		});
	},
	setUserPage: async function(userId) {
		if(userId==globals.id || globals.type!='user') { // If not regular user or own userId
			$('.profile-user-initials').html(globals.initials);
			if(globals.avatar != '') {
				$('.profile-user-img').attr('src', globals.serverBaseUrl+'docs/avatars/'+globals.avatar);
				$('#profileAvatarLink').attr('href', globals.serverBaseUrl+'docs/avatars/'+globals.avatar);
			}
			let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
				showCounter : true,
				history : false,
				captionType : 'data',
				captionsData : 'caption',
				closeText : 'X'
			});
			lightbox.refresh();
			const myFormDiv = '#modProfileForm';
			App.clearFormFields(myFormDiv);
			const generateSelectBoxes = await this.getFormsSelectBoxes([myFormDiv], 'usersController', ['site_u']);
			$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, ap: globals.adminPass, auto_u: userId, req: 'usersController', action: 'fillModUser'}, function(data){
				if(data.ok=="ok") {
					for (const [key, value] of Object.entries(data.users)) {
						$(myFormDiv+' #'+key).val(value);
					}
				}
				else if(data.administrator) {
					$(myFormDiv).closest('div').empty().append('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Vous n\'avez pas les droits suffisants pour afficher cette ressource !</div>');
				}
				else alert("Suite à un problème technique, je n'ai pas retrouvé cet utilisateur !");
				// If userId is different set the right avatar & initials...
				if(userId!=globals.id) {
					$('.profile-user-initials').html(data.users.init_u);
					$('#userPageTitle').text('Profil utilistateur de '+data.users.init_u);
					if(data.avatar_u != '') {
						$('.profile-user-img').attr('src', globals.serverBaseUrl+'docs/avatars/'+data.avatar_u);
						$('#profileAvatarLink').attr('href', globals.serverBaseUrl+'docs/avatars/'+data.avatar_u);
					}
					else {
						const randomPict = Math.floor(Math.random()*6);
						$('.profile-user-img').attr('src', globals.serverBaseUrl+'docs/avatars/profile-'+randomPict+'.png');
						$('#profileAvatarLink').attr('href', globals.serverBaseUrl+'docs/avatars/profile-'+randomPict+'.png');
					}
				}
			}, "json").always(function(){
			});
			if(globals.type=='user') {
				$('.userReadOnly').attr('readonly', true);
				$('.userDisabled').attr('disabled', true);
			}
		}
		else {
			$("#userPageBody").empty().append('<div class="alert alert-danger text-xl text-center" role="alert"><i class="fa fa-2x fa-times-circle"></i><hr>Vous n\'avez pas les droits suffisants pour afficher cette ressource !</div>');
			$("#userPageBody").append('<hr><a href="/users/'+globals.tag+'" class="btn btn-dark btn-lg btn-block shadow"><i class="fa fa-user-circle"></i>&nbsp;Voir Mon Profil</a>');
		}
	},
	setOrganizationPage: function() {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, site: globals.site, req: 'usersController', action: 'getUsersOrg'}, function(data){
			let snippetOrg = '<div class="row">';
			let line = 1;
			let firstOne = 1;
			// snippetOrg += '<button class="btn btn-primary btn-icon ms-1" onclick="App.popModal"' + addUserModal + '"><i class="fa fa-plus"></i></button>'
			data.arrayOrg.forEach(function(user){
				if(globals.site!=0) {
					if(line==1 && firstOne==1) snippetOrg += '<h2>'+user.agency+'</h2>';
				}
				else { // All agencies...
					if(line==1 && firstOne==1) snippetOrg += '<h2>'+user.agency+'</h2>';
					if(line!=user.line) {
						snippetOrg += '<span class="mb-10 mt-4 line shadow-line">&nbsp;</span>';
						snippetOrg += '<h2>'+user.agency+'</h2>';
						line=user.line;
					}
					if(line!=user.line || user.job_u == 'Développeur' && user.job_u == 'Chargé de projet') {
						if(line==1 && firstOne==1) 
						snippetOrg += '<h2>'+user.agency+'</h2>';
						snippetOrg += '<h2>SNS</h2>';
					}
				}
				snippetOrg += '<div class="col-auto d-grid text-center mb-4 mx-auto">';
				const imgLink = (user.avatar_u!='') ? globals.serverBaseUrl+'docs/avatars/'+user.avatar_u : globals.serverBaseUrl+'docs/avatars/profile-'+Math.floor(Math.random()*6)+'.png';
				snippetOrg += (globals.type!='user') ? '<a href="/users/'+user.auto_u+'">' : '<a href="'+imgLink+'" data-simplelightbox="all">';
				snippetOrg += '<img class="org-user-img shadow-lg mb-2" src="'+imgLink+'" /></a>';
				snippetOrg += '<p class="lead"><a href="tel:'+user.ext+'">'+user.ext+'</a><br>'+user.name_u+'<br>'+user.job_u+'</p>';
				snippetOrg += '</div>';
				firstOne++;
			});
			snippetOrg += '</div>';
			$('#usersOrgCont').empty().append(snippetOrg);
			let lightbox = new SimpleLightbox('a[data-simplelightbox="all"]', {
				showCounter : true,
				history : false,
				captionType : 'data',
				captionsData : 'caption',
				closeText : 'X'
			});
			lightbox.refresh();
		}, "json");
	},
	getThe15FuckingGraphs: function (idSeller = 4) {
		globals.charts.forEach(graph => {
			graph.destroy();
		})
		const req = "dashController";
		const action = "getThe15FuckingGraphs";
		const query = "&id=" + globals.id + "&type=" + globals.type + "&site=" + globals.site + "&pwd=" + globals.pwd + "&req=" + req + "&action=" + action + "&vendeur="+idSeller;
		$.post(globals.serverAddress, query, function(data){
			// console.log(data);
			Object.keys(data).forEach(dataKey => {
				// console.log(data[dataKey]);
				const colors = ['CornflowerBlue', 'orange', 'lightblue', 'gray', 'blue', 'green', 'darkblue']
				const dataLabels = data[dataKey][0].map(currentElem => {
					colors.push("#" + Math.floor(Math.random()*16777215).toString(16));
					return currentElem[Object.keys(currentElem)[0]];
				});
				const dataValues = data[dataKey][0].map(currentElem => {
					return currentElem[Object.keys(currentElem)[1]];
				});
				// console.log(dataLabels, dataValues);
				const thisChart = new Chart(document.getElementById(dataKey), {
					type: data[dataKey][1][1],
					data: {
						labels: dataLabels,
						datasets: [{
							label: data[dataKey][1][0],
							// lineTension: 0.3,
							backgroundColor: data[dataKey][1][1] == "pie" ? colors : 'CornFlowerBlue',
							borderColor: "rgba(8, 159, 227, 1)",
							// pointRadius: 3,
							// pointBackgroundColor: "rgba(8, 159, 227, 1)",
							// pointBorderColor: "rgba(8, 159, 227, 1)",
							// pointHoverRadius: 3,
							// pointHoverBackgroundColor: "rgba(8, 159, 227, 1)",
							// pointHoverBorderColor: "rgba(8, 159, 227, 1)",
							// pointHoverBackgroundColor: "rgba(8, 159, 227, 1)",
							// pointHoverBorderColor: "rgba(8, 159, 227, 1)",
							// backgroundColor: "rgba(8, 159, 227, 1)",
							// hoverBackgroundColor: "rgba(8, 159, 227, 1)",
							// borderColor: "#4e73df",
							// pointHitRadius: 10,
							// pointBorderWidth: 2,
							// fill: 'origin',
							data: dataValues
						}]
					},
					options: {
						responsive: true,
						maintainAspectRatio:false,
						legend:{'display':true, position: 'bottom'},
						layout: {
							padding: {
								left: 10,
								right: 25,
								top: 25,
								bottom: 0
							},
							// height: 460,
						},
						scales: {
							x: {
								time: {
									unit: "date"
								},
								gridLines: {
									display: false,
									drawBorder: false
								},
								ticks: {
									maxTicksLimit: 7
								}
							},
							y: {
								ticks: {
									maxTicksLimit: 5,
									padding: 10,
									// Format numbers in the ticks
									callback: function(value, index, values) {
										return App.number_format(value, 2, ',', ' ');
									}
								},
								gridLines: {
									color: "rgb(234, 236, 244)",
									zeroLineColor: "rgb(234, 236, 244)",
									drawBorder: false,
									borderDash: [2],
									zeroLineBorderDash: [2]
								}
							}
						},
						tooltips: {
							backgroundColor: "rgb(255,255,255)",
							bodyFontColor: "#858796",
							titleMarginBottom: 10,
							titleFontColor: "#6e707e",
							titleFontSize: 14,
							borderColor: "#dddfeb",
							borderWidth: 1,
							xPadding: 15,
							yPadding: 15,
							displayColors: false,
							intersect: false,
							mode: "index",
							caretPadding: 10,
							callbacks: {
								label: function(tooltipItem, chart) {
									var datasetLabel =
										chart.datasets[tooltipItem.datasetIndex].label || "";
									return datasetLabel + ": " + App.number_format(tooltipItem.yLabel, 2, ',', ' ');
								}
							}
						}
					}
				});
				globals.charts.push(thisChart);
			})
		}, "json").done(function(){
		}).always(function(){
		});
	},
	fetchSellers: async function () {
		// const myFomId = $(selectInput).closest('form').attr('id');
		// const myForm = document.getElementById(myFomId);
		const sellerSelect = document.querySelector('#sellersOfSite');
		const bodyFormData = new FormData()
		bodyFormData.append('req', "salesController");
		bodyFormData.append('action', "getInputFieldsSellers");
		bodyFormData.append('id', globals.id ?? sessionStorage.getItem("id"));
		bodyFormData.append("pwd", globals.pwd);
		bodyFormData.append('site', globals.site ?? sessionStorage.getItem("site"));
		const response = await fetch(globals.serverAddress, {
			body: bodyFormData,
			method: "POST"
		});
		const data = await response.json();
		if(data.status && data.status == 400) {
			return;
		}
		console.log(data)
		sellerSelect.innerHTML = "";
		const option = document.createElement('option');
		option.value = 0;
		option.innerHTML = "Sélectionnez un vendeur";
		sellerSelect.prepend(option);
		data.vendeurs?.forEach(vendeur => {
			const option = document.createElement('option');
			option.value = vendeur.auto_u;
			option.innerHTML = vendeur.firstname_u;
			sellerSelect.appendChild(option);
		})
		sellerSelect.value = 0;
	},
	makeNiceDateTime: function(d) {
		const jsDay = d.getDate();
		const jsMonth = d.getMonth()+1; // January is zero
		const jsHours = d.getHours();
		const jsMinutes = d.getMinutes();
		const jsSeconds = d.getSeconds();
		const niceDay = (jsDay<10) ? '0'+jsDay : jsDay;
		const niceHours = (jsHours<10) ? '0'+jsHours : jsHours;
		const niceMinutes = (jsMinutes<10) ? '0'+jsMinutes : jsMinutes;
		const niceSeconds = (jsSeconds<10) ? '0'+jsSeconds : jsSeconds;
		const niceMonth = (jsMonth<10) ? '0'+jsMonth : jsMonth;
		const niceDate = niceDay+'/'+niceMonth+'/'+d.getFullYear()+' '+niceHours+':'+niceMinutes+':'+niceSeconds;
		return niceDate;
	},
	makeNiceTime: function(timeInMinutes) {
		const jsHours = Math.floor(timeInMinutes/60);
		const jsMinutes = timeInMinutes-jsHours*60;
		console.debug(jsMinutes);
		const niceHours = (jsHours<10) ? '0'+jsHours : jsHours;
		const niceMinutes = (jsMinutes<10) ? '0'+jsMinutes : jsMinutes;
		const niceTime = niceHours+'h'+niceMinutes;
		return niceTime;
	},
	makeNiceTime24: function(timeInMinutes, offset) {
		const tempDate = new Date(Math.floor(timeInMinutes*60*1000)); // UNIX Timestamp here is GMT+1 Winter time so an hour is added
		const jsHours = Math.floor(tempDate.getHours()-offset);
		const jsMinutes = tempDate.getMinutes();
		const niceHours = (jsHours<10) ? '0'+jsHours : jsHours;
		const niceMinutes = (jsMinutes<10) ? '0'+jsMinutes : jsMinutes;
		const niceTime = niceHours+'h'+niceMinutes;
		console.warn(tempDate+' - '+niceTime+' - '+Math.floor(timeInMinutes*60*1000));
		return niceTime;
	},
	convertStringToDate: function(niceDateTime) { // See makeNiceDateTime => convertStringToDate("19/04/2022 11:20:30")
		let dateComponents = niceDateTime.split(' ');
		let datePieces = dateComponents[0].split("/");
		let timePieces = dateComponents[1].split(":");
		return(new Date(datePieces[2], (datePieces[1] - 1), datePieces[0], timePieces[0], timePieces[1], timePieces[2]));
	},
	printDiv: function(divName){
		var printContents = document.getElementById(divName).innerHTML;
		var originalContents = document.body.innerHTML;
		document.body.innerHTML = printContents;
		window.print();
		document.body.innerHTML = originalContents;
	},
  printDivWithVals: function(divName, childBody) {
    let hiddenElems = [];
    document.body.childNodes.forEach(bodyChild => {
      if(bodyChild.nodeName == '#text' || bodyChild.nodeName == '#comment') {
        return
      }
      else if (bodyChild.id == childBody) {
        return
      }
      else {
        hiddenElems.push({elem: bodyChild, display: bodyChild.style.display});
        bodyChild.style.display = "none";
      }
    })
    let printContents = document.getElementById(divName);
    let contentClone = printContents.cloneNode(true);
    contentClone.style.position = "absolute";
    contentClone.style.top = "0";
    contentClone.style.left = "0";
    contentClone.style.width = "100vw";
    contentClone.style.height = "100vh";
    contentClone.style.zIndex = "9999";
    contentClone.style.backgroundColor = "white";
    contentClone.querySelectorAll('.erase-button').forEach(elem => elem.remove())
    document.body.appendChild(contentClone);
    window.print();
     //workaround for Chrome bug - https://code.google.com/p/chromium/issues/detail?id=141633
    if (window.stop) {
      location.reload(); //triggering unload (e.g. reloading the page) makes the print dialog appear
      window.stop(); //immediately stop reloading
    }
    hiddenElems.forEach(hiddenElem => {
      hiddenElem.elem.style.display = hiddenElem.display;
    })
    contentClone.remove();
    return false;
  },
	btnCheckThatBox: function(thisBtn) {
		const box2Check = $(thisBtn).prev('input[type="checkbox"]');
		let state = $(box2Check).is(':checked');
		state = !state;
		$(box2Check).attr('checked', state);
	},
	darkSwitchAdditionnalClasses: function(thisBtn) {
		let state = $(thisBtn).is(':checked');
		if(state) $('.sidenav').removeClass('sidenav-light').addClass('sidenav-dark');
		else $('.sidenav').removeClass('sidenav-dark').addClass('sidenav-light');
	},
	fullScreen: function(){
		$('body').addClass('sidenav-toggled');
		$('#sidebarToggle').html('<i class="fa fa-2x fa-bars"></i>');
		$('header').hide();
		$('#mainContent').removeClass('mt-n10');
		$('#fullScreenBack').show();
	},
	fullScreenBack: function(){
		if(localStorage.getItem('sb|sidebar-toggle') !== 'true') {
			$('body').removeClass('sidenav-toggled');
			$('#sidebarToggle').html('<i class="fa fa-2x fa-angle-double-left"></i>');
		}
		$('header').show();
		$('#mainContent').addClass('mt-n10');
		$('#fullScreenBack').hide();
	},
	getScrollXY: function () {
		var scrOfX = 0, scrOfY = 0;
		if( typeof( window.pageYOffset ) == 'number' ) {
			//Netscape compliant
			scrOfY = window.pageYOffset;
			scrOfX = window.pageXOffset;
		} else if( document.body && ( document.body.scrollLeft || document.body.scrollTop ) ) {
			//DOM compliant
			scrOfY = document.body.scrollTop;
			scrOfX = document.body.scrollLeft;
		} else if( document.documentElement && ( document.documentElement.scrollLeft || document.documentElement.scrollTop ) ) {
			//IE6 standards compliant mode
			scrOfY = document.documentElement.scrollTop;
			scrOfX = document.documentElement.scrollLeft;
		}
		return [ scrOfX, scrOfY ];
	},
	getDocHeight: function () {
		var D = document;
		return Math.max(
			D.body.scrollHeight, D.documentElement.scrollHeight,
			D.body.offsetHeight, D.documentElement.offsetHeight,
			D.body.clientHeight, D.documentElement.clientHeight
		);
	},
};

// Expose App object to window object => need this to use click event in html
window.App = App;
require('./router.js');

(function() {
	// If not logged in and page is not login redirects to login page...
	if ($.localStorage.getItem('pass')!='OK' && document.URL.indexOf( 'login.html' ) === -1)
	{
		document.location.href='/login.html';
	}
	else if (document.URL.indexOf( 'login.html' ) !== -1) {
		// It's login page.
		App.init();
	}
	else {
		// pass ok...
		App.init();
	}
})();