import Navigo from 'navigo';
// import * as feather from 'feather-icons'; // if not imported with webpack.ProvidePlugin
// const feather = require('feather-icons');

// getElementById wrapper
function getId(id) {
    return document.getElementById(id);
}
// asyncrhonously fetch the html template partial from the file directory, then set its contents to the html of the parent element
function loadHTML(url, id) {
    // alert(url+' / '+id);
    let req = new XMLHttpRequest();
    req.open('GET', url);
    req.send();
    req.onload = () => {
        getId(id).innerHTML = req.responseText;
    };
}
function setPageAfterload (link) {
    console.log(link);
    // Remove active state to all sidbar nav links then...
    $("#layoutSidenav_nav .sidenav a.nav-link").not('[data-toggle="modal"]').each(function() {
        $(this).removeClass("active");
    });
    // Add active state to sidbar nav links
    const targetAnchors = document.body.querySelectorAll('[href="/' + link + '"].nav-link');
    targetAnchors.forEach(targetAnchor => {
        let parentNode = targetAnchor.parentNode;
        while (parentNode !== null && parentNode !== document.documentElement) {
            if (parentNode.classList.contains('collapse')) {
                parentNode.classList.add('show');
                const parentNavLink = document.body.querySelector(
                    '[data-bs-target="#' + parentNode.id + '"]'
                );
                parentNavLink.classList.remove('collapsed');
                parentNavLink.classList.add('active');
            }
            parentNode = parentNode.parentNode;
        }
        targetAnchor.classList.add('active');
    });
    /* // Old one
    $("#layoutSidenav_nav .sidenav a.nav-link").not('[data-toggle="modal"]').each(function() {
        $(this).removeClass("active");
        if (this.href.search(link) !== -1) {
            $(this).addClass("active");
            $(this).closest('.collapse').addClass('show').prev('.nav-link').removeClass('collapsed').addClass('active');
        }
    });
    */
    if(link=='index.html') $('.nav-link-def-route').addClass('active').closest('.collapse').addClass('show').prev('.nav-link').removeClass('collapsed').addClass('active');
    // Now doing things like binding UI Actcions for this page...
    // Activate Feather icons
    feather.replace();
    // Display .adminOnly, .accountantOnly... Objects
    App.refreshMenuDisplay();
    $.sessionStorage.setItem('myPage', link); // Change current page to session
    // Smooth Scroll to div...
    App.refreshSmoothScroll();
    // Add the following code if you want the name of the file appear on select
    $(".custom-file-input").on("change", function(e) {
        //const fileName = $(this).val().split("\\").pop();
        let fileName = "";
        //e.target.files.forEach(function(item){ fileName += '"'+item.name+'"';});
        //alert(e.target.files[0].name);
        for (var i = 0; i < e.target.files.length; i++) {
            fileName += '"'+e.target.files[i].name+'" ';
        }
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    });
    // Is it Mobile device
    const isMobile = (/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent) || window.innerWidth<768) ? true : false;
    if(isMobile) {
        $('#myTab').removeClass('nav-tabs').addClass('nav-pills');
    }
}
function destroyDataTables() {
    // Make sure previous DataTables are Destroyed => fixedHeader bug fix !
    $('.dataTable').DataTable().destroy(true);
}
// Now the router... Depending on login
if($.localStorage.getItem('pass')=='OK' && document.URL.indexOf( 'login.html' ) === -1) {
    const myDomain = window.location.origin; // https://my.domain
    const rootUrl = (myDomain.indexOf('localhost')!==-1) ? '/' : '/'; // if online version is in a subfolder
    const router = new Navigo(rootUrl); // params are: root, useHash (Defaults to: false) and hash (Defaults to: '#')
    // use #! to hash
    // const router = new Navigo(null, true, '#!'); // params are: root, useHash (Defaults to: false) and hash (Defaults to: '#')
    router.hooks({
        // Ensure new data-navigo links work.
        after: function() {
            router.updatePageLinks();
        },
    });
    const routerContainer = document.getElementById('routerContainer');
    const routerContainerExist = (routerContainer) ? true : false;
    if(!routerContainerExist) document.location.href = './';
    // Direct To Some Page...
    // const goTo = App.urlParam('goto', document.URL); // Where are we going
    // if(goTo == 'page') router.navigate("/page");

    router.on({
        // 'routerContainer' is the id of the div element inside which we render the HTML
        '/': () => {
            destroyDataTables();
            fetch('/global.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                setPageAfterload('index.html');
                App.setGlobalPage();
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/global': () => {
            destroyDataTables();
            fetch('/global.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                setPageAfterload('global');
                App.setGlobalPage();
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/individuel': () => {
            destroyDataTables();
            fetch('/individuel.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                setPageAfterload('individuel');
                App.setDashPage('individual');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/graphique': () => {
            destroyDataTables();
            fetch('/graphique.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                setPageAfterload('graphique');
                App.setDashPage('global');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/ventes': () => {
            destroyDataTables();
            fetch('/sales.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setSalesListPage('getSalesList', 'Les 1000 dernières ventes', false);
                setPageAfterload('ventes');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/ventes/:id': ({data}) => {
            destroyDataTables();
            fetch('/sales.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setSalePage(data.id);
                setPageAfterload('ventes');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/mes-ventes': () => {
            destroyDataTables();
            fetch('/sales.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setSalesListPage('getMySalesList', 'Mes ventes', false);
                setPageAfterload('ventes');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/toutes-ventes': () => {
            destroyDataTables();
            fetch('/sales.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setSalesListPage('getAllSalesList', 'Toutes les ventes', false);
                setPageAfterload('ventes');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/options': () => {
            try {
                destroyDataTables();
            }
            catch(e) {
                console.log("could've been an issue " + e)
            }
            fetch('/options.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setOptionsListPage();
                setPageAfterload('options');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/users': () => {
            destroyDataTables();
            fetch('/users.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setUsersListPage();
                setPageAfterload('users');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/users/:id': ({data}) => {
            destroyDataTables();
            fetch('/user.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setUserPage(data.id);
                if(data.id==$.localStorage.getItem('id')) setPageAfterload('users/'+data.id);
                else setPageAfterload('users');
                // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/org': () => {
            destroyDataTables();
            fetch('/org.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setOrganizationPage();
                setPageAfterload('org');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        // '/stats': () => {
        //     destroyDataTables();
        //     fetch('/stats/index.html').then(function (response) {
        //         return response.text();
        //     }).then(function (html) {
        //         document.body.innerHTML = html;
        //         // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        //     }).catch(function (err) {
        //         console.warn('Something went wrong.', err);
        //     });
        // },
        '/dc': () => {
            App.logMeOut();
            // loadHTML('/logout.html', 'routerContainer');
        },
    });

    router.on(() => {
        fetch('/global.html').then(function (response) {
            return response.text();
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.dashActions();
            setPageAfterload('index.html');
            // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    });

    // set the 404 route
    router.notFound((query) => {
        loadHTML('/404.html', 'routerContainer');
        setPageAfterload('404');
        console.warn('Route not found');
        console.warn(query);
    });

    router.resolve();
    /*
    fetch('http://example.org').then(function(response) {
        if (response.ok) {
            return response.json()
        } else {
            throw ("Error " + response.status);
        }
    }).then(function(data) {
        // do what you have to do with data
    }).catch(function(err) {
        console.log(err);
    });
    */
}