/*!
* Start Bootstrap - SB Admin Pro v2.0.2 (https://shop.startbootstrap.com/product/sb-admin-pro)
* Copyright 2013-2021 Start Bootstrap
* Licensed under SEE_LICENSE (https://github.com/StartBootstrap/sb-admin-pro/blob/master/LICENSE)
*/
// import * as feather from 'feather-icons'; // if not imported with webpack.ProvidePlugin
import * as bootstrap from 'bootstrap';

window.addEventListener('DOMContentLoaded', event => {
    // Activate feather
    feather.replace();

    // Enable tooltips globally
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
    });

    // Enable popovers globally
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl);
    });

    // Activate Bootstrap scrollspy for the sticky nav component
    const stickyNav = document.body.querySelector('#stickyNav');
    if (stickyNav) {
        new bootstrap.ScrollSpy(document.body, {
            target: '#stickyNav',
            offset: 82,
        });
    }
    const BOOTSTRAP_LG_WIDTH = 992;
    const BOOTSTRAP_XL_WIDTH = 1200;
    // Toggle the side navigation
    if (window.innerWidth >= BOOTSTRAP_XL_WIDTH) $('#sidebarToggle').html('<i class="fa fa-2x fa-angle-double-left"></i>');
    const sidebarToggle = document.body.querySelector('#sidebarToggle');
    if (sidebarToggle) {
        // Uncomment Below to persist sidebar toggle between refreshes
        /*
        if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
            document.body.classList.toggle('sidenav-toggled');
            $('#sidebarToggle').html('<i class="fa fa-2x fa-bars"></i>');
        }
        */
        sidebarToggle.addEventListener('click', event => {
            event.preventDefault();
            document.body.classList.toggle('sidenav-toggled');
            localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sidenav-toggled'));
            if (window.innerWidth >= BOOTSTRAP_XL_WIDTH) {
                if($('body').hasClass('sidenav-toggled')) $('#sidebarToggle').html('<i class="fa fa-2x fa-bars"></i>');
                else $('#sidebarToggle').html('<i class="fa fa-2x fa-angle-double-left"></i>');
            }
            else {
                if($('body').hasClass('sidenav-toggled')) $('#sidebarToggle').html('<i class="fa fa-2x fa-angle-double-left"></i>');
                else $('#sidebarToggle').html('<i class="fa fa-2x fa-bars"></i>');
            }
        });
    }

    // Close side navigation when width < LG or XL (Search for layoutSidenav in styles.css and change min-width in media queries to 1200px for XL to work)
    const sidenavContent = document.body.querySelector('#layoutSidenav_content');
    if (sidenavContent) {
        sidenavContent.addEventListener('click', event => {
            if (window.innerWidth >= BOOTSTRAP_XL_WIDTH) {
                return;
            }
            if (document.body.classList.contains("sidenav-toggled")) {
                document.body.classList.toggle("sidenav-toggled");
                $('#sidebarToggle').html('<i class="fa fa-2x fa-bars"></i>');
            }
        });
    }
    // Done in the router
    /*
    // Add active state to sidbar nav links
    let activatedPath = window.location.pathname.match(/([\w-]+\.html)/, '$1');

    if (activatedPath) {
        activatedPath = activatedPath[0];
    } else {
        activatedPath = 'index.html';
    }

    const targetAnchors = document.body.querySelectorAll('[href="' + activatedPath + '"].nav-link');

    targetAnchors.forEach(targetAnchor => {
        let parentNode = targetAnchor.parentNode;
        while (parentNode !== null && parentNode !== document.documentElement) {
            if (parentNode.classList.contains('collapse')) {
                parentNode.classList.add('show');
                const parentNavLink = document.body.querySelector(
                    '[data-bs-target="#' + parentNode.id + '"]'
                );
                parentNavLink.classList.remove('collapsed');
                parentNavLink.classList.add('active');
            }
            parentNode = parentNode.parentNode;
        }
        targetAnchor.classList.add('active');
    });
    */
});